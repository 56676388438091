import * as API from 'src/api';

import { UserPush } from '../types/index';

const API_ENDPOINT_USERPUSHES = 'get-user-pushes';

interface IGetUserPushesResponse {
  pushes: UserPush[];
}

export const getUserPushes = (): Promise<UserPush[]> =>
  API.request<IGetUserPushesResponse>({
    method: 'get',
    url: API_ENDPOINT_USERPUSHES,
  }).then((response) => response.pushes);
