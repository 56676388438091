










































import Vue from 'vue';

import { authStoreMapper } from '../store';
import { LOCALSTORAGE_AGECHECK } from '../const';
import Store from '@/storage';

export default Vue.extend({
  name: 'AuthWelcomeScreenSlot',
  data: () => ({
    ageCheckAnswered: false,
  }),
  created() {
    this.setAgeCheck(Store.getSync(LOCALSTORAGE_AGECHECK) || false);
  },
  mounted() {
    this.init();
  },
  computed: {
    ageCheckEnabled(): boolean {
      return !this.isAuthorized && this.settings.useAgeDisclaimer && !this.ageCheck;
    },
    ...authStoreMapper.mapGetters(['isAuthorized', 'settings']),
    ...authStoreMapper.mapState(['ageCheck']),
  },
  methods: {
    init() {
      if (this.ageCheckEnabled) {
        this.$f7ready(() => {
          this.$f7.loginScreen.open('.welcome-screen');
        });
      }
    },
    onConfirmClick() {
      this.setAgeCheck(true);

      this.ageCheckAnswered = true;
      this.$f7.loginScreen.close('.welcome-screen');
    },
    onDeclineClick() {
      this.ageCheckAnswered = true;
    },
    onCloseClick() {
      navigator?.app?.exitApp();
    },
    ...authStoreMapper.mapMutations(['setAgeCheck']),
  },
  watch: {
    settings() {
      if (!this.ageCheck) {
        this.init();
      }
    },
  },
});
