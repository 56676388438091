import { IModule } from '@/types';

import Vue from 'vue';
import { registerModule } from 'vuex-smart-module';
import { faNewspaper } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import * as Misc from 'src/misc';
import config from './news.conf.json';

import store from 'src/store';
import NewsStore from './store';

import News from './components/news.vue';
import NewsPush from './components/news-push.vue';
import NewsPopup from './components/news-popup.vue';

library.add(faNewspaper, faNewspaper, faTimes);

export function init() {
  Vue.component('news', News);
  Vue.component('news-push', NewsPush);
  Vue.component('news-uni-popup', NewsPopup);

  const routes = [
    {
      component: Vue.component('news'),
      path: config.url,
    },
  ];

  registerModule(store, ['news'], 'news/', NewsStore);

  Misc.registerModuleConfig(config as IModule);
  Misc.registerModuleRoutes(routes);
}
