































import { defineComponent, PropType } from 'vue';

import { INewsItem } from '../types';

import { appStoreMapper } from 'src/store/appstore';
import { newsStoreMapper } from '../store';
import { formatDate, getImageThumbnailPath } from '@/utils';

import NewsPopup from './news-popup.vue';

export default defineComponent({
  name: 'NewsItem',
  components: { NewsPopup },
  props: {
    item: {
      type: Object as PropType<INewsItem>,
      required: true,
    },
  },
  data: () => ({
    popupOpened: false,
  }),
  computed: {
    date(): string {
      if (Number.isNaN(new Date(this.item.date).getDate())) {
        return '';
      }

      const announceDate = new Date(this.item.date);
      const nowDate = new Date();
      const thisYear = nowDate.getFullYear() === announceDate.getFullYear();

      return formatDate(new Date(this.item.date), {
        locale: this.locale,
        type: thisYear ? 'dateLongNoYear' : 'dateLong',
      });
    },
    image(): string {
      return this.fullImagePath(
        getImageThumbnailPath(this.item.icon, 'scale', {
          width: this.deviceWidth,
          height: 0,
        }),
      );
    },
    ...appStoreMapper.mapGetters(['clientName', 'fullImagePath', 'deviceWidth']),
    ...appStoreMapper.mapState(['locale']),
    ...newsStoreMapper.mapGetters(['settings']),
  },
  methods: {
    showPopup() {
      this.popupOpened = true;
    },
  },
});
