import { Mutations } from 'vuex-smart-module';

import Storage from '@/storage';
import { IUser } from '@/types';

import { LOCALSTORAGE_AGECHECK } from '../const';
import AuthState from './state';

export default class AuthMutations extends Mutations<AuthState> {
  setAuthPending(value: boolean) {
    this.state.authPending = value;
  }

  setRegisteringDevice(value: boolean) {
    this.state.registeringDevice = value;
  }

  setResetPasswordDelay(date: Date) {
    this.state.resetPasswordDelay = date;
  }

  setAgeCheck(value: boolean) {
    this.state.ageCheck = value;

    Storage.setSync(LOCALSTORAGE_AGECHECK, value);
  }

  setAfterRegisterCallback(func: Nullable<(user: IUser) => void>) {
    this.state.afterRegisterCallback = func;
  }

  setAfterLoginCallback(func: Nullable<(user: IUser) => void>) {
    this.state.afterLoginCallback = func;
  }
}
