import dateFormat from 'dateformat';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

export type FormatDateOptions = {
  locale?: string;
  type?:
    | 'dateShort'
    | 'dateLong'
    | 'timeShort'
    | 'timeLong'
    | 'datetimeShort'
    | 'datetimeLong'
    | 'datetimeShortClear'
    | 'dateLongNoYear'
    | 'dateLongNoMonth'
    | 'ISO';
  intlOptions?: Intl.DateTimeFormatOptions;
  formatString?: string;
};

let _locale: string = 'ru';
const dateFnsLocales: { [key: string]: Locale } = { ru };

function formatDate(date: Date, options?: FormatDateOptions): string {
  const locale = options?.locale || _locale;
  let intlOptions: Intl.DateTimeFormatOptions;
  let callback: (value: string) => string = (value) => value;

  if (options?.formatString) {
    return format(date, options.formatString, {
      locale: dateFnsLocales[locale],
    });
  } else if (options?.type && options.type === 'ISO') {
    return dateFormat(date, 'isoDateTime');
  } else if (options?.type) {
    switch (options.type) {
      case 'timeShort':
        intlOptions = {
          hour: 'numeric',
          minute: '2-digit',
        };
        break;
      case 'timeLong':
        intlOptions = {
          hour: 'numeric',
          minute: '2-digit',
          second: '2-digit',
        };
        break;
      case 'datetimeShort':
        intlOptions = {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
        };
        break;
      case 'datetimeLong':
        intlOptions = {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
        };
        break;
      case 'dateLong':
        intlOptions = {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        };
        break;
      case 'dateLongNoYear':
        intlOptions = {
          day: 'numeric',
          month: 'long',
        };
        break;
      case 'dateLongNoMonth':
        intlOptions = {
          day: 'numeric',
        };
        break;
      case 'datetimeShortClear':
        intlOptions = {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
        };
        callback = (value: string) => {
          return value.replace(',', '');
        };
        break;
      case 'dateShort':
      default:
        intlOptions = {};
    }
  } else {
    intlOptions = options?.intlOptions || {};
  }

  const formatter = new Intl.DateTimeFormat(locale, intlOptions);
  return callback(formatter.format(date));
}

function setFormatDateLocale(l: string) {
  _locale = l;
}

export { formatDate, setFormatDateLocale };
