import { Actions, Context } from 'vuex-smart-module';
import { Store } from 'vuex';

import AppStore from '@/store/appstore';
import BonusState from './state';
import BonusGetters from './getters';
import BonusMutations from './mutations';

export default class BonusActions extends Actions<
  BonusState,
  BonusGetters,
  BonusMutations,
  BonusActions
> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }
}
