import { createI18nMessage } from '@vuelidate/validators';

import i18n from '@/translate/lang';

const validator = {
  $validator: (value: string) =>
    !value ||
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/g.test(
      value,
    ),
  $params: {
    type: 'email',
  },
};

function translationFunction(validator: string) {
  return i18n.t('global.validators.email.error').toString();
}

const withI18nMessage = createI18nMessage({ t: translationFunction });

export default withI18nMessage(validator);
