import { IHistoryItem } from '../types';

export default class HistoryState {
  history: IHistoryItem[] = [];
  triggerHistoryUpdate = false;
  loadingHistory = false;
  loadingHistoryAfter = false;
  loadingHistoryPromise: Promise<IHistoryItem[]> | null = null as any;
  loadingHistoryAfterPromise: Promise<IHistoryItem[]> | null = null as any;
}
