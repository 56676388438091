















import { defineComponent } from 'vue';

import { appStoreMapper } from 'src/store/appstore';
import { feedbackStoreMapper } from '../store';

import FeedbackForm from './feedback-form.vue';
import NavbarSlotLeft from 'src/components/navbar-slot-left.vue';

export default defineComponent({
  name: 'Feedback',
  components: { FeedbackForm, NavbarSlotLeft },
  computed: {
    ...appStoreMapper.mapGetters(['miniapp']),
    ...appStoreMapper.mapState(['domain']),
    ...feedbackStoreMapper.mapGetters(['settings']),
    ...feedbackStoreMapper.mapState(['feedback']),
  },
  methods: {
    ...feedbackStoreMapper.mapMutations(['setFeedback']),
  },
  watch: {
    domain() {
      this.setFeedback([]);
    },
  },
});
