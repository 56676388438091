import { TResponse } from 'src/api';

export enum RewardType {
  fix = 'fix',
  flex = 'flex',
}

export enum ReferralType {
  first = 'firstOrder',
  all = 'allOrders',
}

export interface IModuleSettings {
  canEnterCode: boolean;
  code: number | null;
  homeShowShare: boolean;
  menuShowShare: boolean;
  message?: string;
  referalBonus: string;
  refererBonus: string;
  refererReward: RewardType;
  type: ReferralType;
  regDay?: number;
}

export interface IActivateCodeRequest {
  code: string;
}

export type ActivateCodeResponse = TResponse & {
  [key: string]: any;
};
