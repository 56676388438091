




































































































import Vue from 'vue';
import { f7 } from 'framework7-vue';

import { IUser } from '@/types';

import { appStoreMapper } from '@/store/appstore';
import { authStoreMapper } from '../store';

import { useAuth } from '../composition';
import * as API from '../api';
import { formatDate } from '@/utils';

import NavbarSlotLeft from '@/components/navbar-slot-left.vue';
import AuthSettingsSlot from './AuthSettingsSlot.vue';

export default Vue.extend({
  components: {
    NavbarSlotLeft,
    AuthSettingsSlot,
  },
  setup() {
    return { ...useAuth() };
  },
  data: () => ({
    emailReceiptActive: false,
  }),
  computed: {
    birthday(): Nullable<string> {
      if (this.user?.bday) {
        return formatDate(new Date(this.user.bday), {
          locale: this.locale,
          type: 'dateLong',
        });
      }

      return null;
    },
    bonus(): Nullable<number> {
      return this.user?.bonus;
    },
    group(): Nullable<string> {
      return this.user?.groupName;
    },
    groupBonus(): Nullable<number> {
      return this.user?.groupBonus;
    },
    id(): Nullable<number> {
      return this.user?.idUser;
    },
    name(): Nullable<string> {
      return this.userNameFull;
    },
    username(): Nullable<string> {
      return this.user?.username;
    },
    email(): Nullable<string> {
      return this.user?.email;
    },
    emailReceiptAvailability(): boolean {
      return this.settings.receiptToEmailModuleEnabled && this.settings.email;
    },
    emailReceiptNeedEmail(): boolean {
      return !this.user?.email;
    },
    ...appStoreMapper.mapGetters(['miniappHideElements', 'user', 'userNameFull']),
    ...appStoreMapper.mapState(['locale']),
    ...authStoreMapper.mapGetters(['isAuthorized', 'settings']),
  },
  methods: {
    goToEdit() {
      f7.views.current.router.navigate('/profile/edit');
    },
    pageBeforeIn() {
      if (!this.settings.tab) {
        f7.toolbar.hide('.views .tabbar');
      }
    },
    back() {
      if (!this.settings.tab) {
        f7.toolbar.show('.views .tabbar');
      }

      f7.views.current.router.back(f7.views.current.router.history[0], {
        force: true,
      });
    },
    onEmailReceiptClick() {
      if (this.emailReceiptNeedEmail) {
        return false;
      }

      this.emailReceiptActive = !this.emailReceiptActive;

      API.setReceiptSaving({ state: this.emailReceiptActive })
        .then((response) => {
          const user = JSON.parse(JSON.stringify(this.user)) as IUser;
          user.sendReceiptToEmail = response.value;
          this.setUser(user);
        })
        .catch((e) => {
          this.emailReceiptActive = !this.emailReceiptActive;
        });
    },
    ...appStoreMapper.mapMutations(['setUser']),
  },
  watch: {
    user: {
      handler() {
        this.emailReceiptActive = !!this.user?.sendReceiptToEmail;
      },
      immediate: true,
    },
  },
});
