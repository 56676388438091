
































import Vue, { PropType } from 'vue';

import { IBranch, IBranchWithDistance } from '@/types';

import { appStoreMapper } from '@/store/appstore';
import { openUrl, getDistanceBetweenTwoPoints } from '@/misc';
import { timeZone, getFormattedDistance, checkWorkingTime } from '@/utils';

export default Vue.extend({
  name: 'BranchList',
  props: {
    from: { type: Number as PropType<number>, default: 0 },
    limit: { type: Number as PropType<number>, default: 0 },
    noTitle: Boolean as PropType<boolean>,
    noDesc: Boolean as PropType<boolean>,
    customBranches: Array as PropType<IBranch[]>,
  },
  computed: {
    branchesList(): IBranchWithDistance[] {
      const origin = this.customBranches ? this.customBranches : this.branches;

      let branchesWithDistance = origin.map((branch) => {
        let distance =
          this.currentCoords.lat || this.currentCoords.lng
            ? getDistanceBetweenTwoPoints(this.currentCoords, {
                lat: parseFloat(branch.lat),
                lng: parseFloat(branch.lng),
              })
            : 0;
        let formattedDistance = getFormattedDistance(distance, this.$i18n);

        return {
          distance,
          formattedDistance,
          ...branch,
        };
      });

      if (this.currentCoords.lat || this.currentCoords.lng) {
        branchesWithDistance = branchesWithDistance.sort(
          (a, b) => a.distance - b.distance,
        );
      }

      const array = this.from
        ? branchesWithDistance.slice(this.from)
        : branchesWithDistance;
      return this.limit ? array.slice(0, this.limit) : array;
    },
    ...appStoreMapper.mapGetters(['branches', 'timeZoneOptions']),
    ...appStoreMapper.mapState(['currentCoords']),
  },
  methods: {
    call(link: string, e: Event) {
      openUrl(link, '_system', e);
    },
    contactPhoneFormatted(str: string) {
      let cleaned = ('' + str).replace(/\D/g, '');
      let match = cleaned.match(/^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/);

      if (match) {
        let intlCode = match[1] ? match[1] : '7';
        return [
          '+',
          intlCode,
          ' ',
          '(',
          match[2],
          ') ',
          match[3],
          '-',
          match[4],
          '-',
          match[5],
        ].join('');
      }

      return '';
    },
    workingTime(branch: IBranchWithDistance): WorkingSchedule {
      return checkWorkingTime({branchOrSchedule: branch, timezoneOptions: this.timeZoneOptions, detailed: true}) as WorkingSchedule;
    },
    workingTimeFormatted(branch: IBranchWithDistance): string {
      if (!branch.startTime || !branch.endTime) return '';

      const partsStart = branch.startTime.split(':');
      const partsEnd = branch.endTime.split(':');

      let startTime = '';
      let endTime = '';

      if (partsStart && partsStart.length >= 2) {
        startTime = timeZone(
          `${new Date().getFullYear()}-06-10T${partsStart[0].padStart(
            2,
            '0',
          )}:${partsStart[1].padStart(2, '0')}:00`,
          { type: 'timeShort', timezone: this.timeZoneOptions },
        );
      }

      if (partsEnd && partsEnd.length >= 2) {
        endTime = timeZone(
          `${new Date().getFullYear()}-06-10T${partsEnd[0].padStart(
            2,
            '0',
          )}:${partsEnd[1].padStart(2, '0')}:00`,
          { type: 'timeShort', timezone: this.timeZoneOptions },
        );
      }

      return `${startTime} - ${endTime}`;
    },
  },
});
