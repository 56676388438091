













import { computed, defineComponent, PropType } from 'vue';

import { ReceiveType } from '../types';

import { useCatalogStore } from '../store';
import i18n from '@/translate/lang';

export default defineComponent({
  name: 'CatalogFormBar',
  props: {
    label: String as PropType<string>,
  },
  setup(props, { emit }) {
    const catalogStore = useCatalogStore();

    const orderButtonName = computed<string>(() => {
      const defaultButtonName = `${i18n.t('modules.catalog.form.action.send')}`;
      return props.label || defaultButtonName;
    });
    const closed = computed<boolean>(() => {
      let flag = false;

      if (
        catalogStore.state.receive === ReceiveType.pickup &&
        catalogStore.getters.notWorkingTime(catalogStore.state.pickupPoint)
      ) {
        flag = true;
      } else if (
        catalogStore.state.receive === ReceiveType.delivery &&
        catalogStore.getters.deliveryNotWorkingTime
      ) {
        flag = true;
      }

      return flag;
    });

    function onClick() {
      if (
        !catalogStore.state.receive ||
        (catalogStore.state.receive === ReceiveType.pickup &&
          !catalogStore.getters.notWorkingTime(catalogStore.state.pickupPoint)) ||
        (catalogStore.state.receive === ReceiveType.delivery && !catalogStore.getters.deliveryNotWorkingTime)
      ) {
        emit('click');
      }
    }

    return { orderButtonName, closed, onClick };
  },
});
