












































import { defineComponent } from 'vue';

import bus from 'src/bus';
import { appStoreMapper } from 'src/store/appstore';

import BonusIcon from 'src/components/bonus-icon.vue';
import WelcomeBonusSlot from './welcome-bonus-slot.vue';

export default defineComponent({
  name: 'Welcome',
  components: { BonusIcon, WelcomeBonusSlot },
  data: () => ({
    showWelcomeScreen: false,
  }),
  created() {
    bus.$on('showWelcomeScreen', () => {
      this.setSecondRun(true);
      this.showWelcomeScreen = true;
    });
  },
  computed: {
    isAuthorized(): boolean {
      return !!this.user?.idUser;
    },
    ...appStoreMapper.mapGetters(['user']),
  },
  methods: {
    goToAuth() {
      this.$f7?.views.current.router.navigate('/auth/');
      this.showWelcomeScreen = false;
    },
    close() {
      this.showWelcomeScreen = false;
    },
    ...appStoreMapper.mapMutations(['setSecondRun']),
  },
});
