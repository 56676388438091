






















































import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { f7 } from 'framework7-vue';

import { ReceiveType } from '@/modules/catalog/dist/types';

import { useAppStore } from '@/store/appstore';
import { useCatalogStore } from '../store';
import bus from '@/bus';
import { formatNumber } from '@/utils';

import AnimatedNumber from '@/components/AnimatedNumber.vue';
import BonusIcon from 'src/components/bonus-icon.vue';
import CatalogBarWrapper from './CatalogBarWrapper.vue';
import FieldInput from '@/components/FieldInput.vue';
import getLocalizedPrice from '@/utils/getLocalizedPrice';

function useInput() {
  const catalogStore = useCatalogStore();

  const amount = ref<number>(0);

  function onInput(event: InputEvent) {
    let value = parseInt((event.target as HTMLInputElement).value, 10) || 0;

    if (value > catalogStore.getters.bonusPayOrderMaxAmount) {
      value = catalogStore.getters.bonusPayOrderMaxAmount;
      (event.target as HTMLInputElement).value = Number(
        catalogStore.getters.bonusPayOrderMaxAmount,
      ).toString();
    } else if (value < 0) {
      value = 0;
      (event.target as HTMLInputElement).value = Number(0).toString();
    }

    amount.value = value;
  }

  function onContinueClicked() {
    f7.views.current.router.navigate('/catalog/form');
  }

  onMounted(() => {
    bus.$on('catalog:bonus-confirm', onContinueClicked);

    watch(
      () => amount.value,
      () => {
        bus.$emit('catalog:total-bonus', amount.value);
        catalogStore.commit('setPayByBonusOrderAmount', amount.value);
      },
      {
        immediate: true,
      },
    );
  });

  return {
    amount,
    onInput,
    bonusPayOrderMaxAmount: catalogStore.getters.bonusPayOrderMaxAmount,
  };
}

function useBarControls() {
  const catalogStore = useCatalogStore();

  function onPageBeforeIn() {
    catalogStore.commit('setInBonus', true);
  }

  function onPageBeforeOut() {
    catalogStore.commit('setInBonus', false);
  }

  return {
    onPageBeforeIn,
    onPageBeforeOut,
  };
}

export default defineComponent({
  name: 'BonusPayOrder',
  methods: { formatNumber },
  components: {
    AnimatedNumber,
    BonusIcon,
    CatalogBarWrapper,
    FieldInput,
  },
  setup() {
    const appStore = useAppStore();
    const catalogStore = useCatalogStore();
    const inputElement = useInput();
    const barControls = useBarControls();

    const miniappHideElements = appStore.getters.miniappHideElements;

    const totalProductsPriceComputed = computed<number>(() => {
      switch (catalogStore.state.receive) {
        case ReceiveType.delivery:
          return catalogStore.getters.totalPriceDelivery;
        case ReceiveType.pickup:
          return catalogStore.getters.totalPricePickup;
        default:
          return catalogStore.getters.totalProductsPrice;
      }
    });

    const resultSum = computed<number>(() => {
      const sum = totalProductsPriceComputed.value - inputElement.amount.value;

      if (appStore.getters.decimalOptions.decimal != null) {
        return sum;
      } else {
        return Math.round(sum);
      }
    });

    function localizePrice(value: number): string {
      return getLocalizedPrice(
        value,
        appStore.getters.currency,
        appStore.getters.decimalOptions.decimalRoundingRule,
        appStore.getters.decimalOptions.decimal,
        {
          useMils: true,
        },
      );
    }

    function formatPriceValue(value: number): string {
      if (value == null) {
        return getLocalizedPrice(value, appStore.getters.currency);
      }

      if (appStore.getters.decimalOptions.decimal == null) {
        return localizePrice(Math.round(value));
      }

      return localizePrice(value);
    }

    return {
      resultSum,
      formatPriceValue,
      miniappHideElements,
      ...barControls,
      ...inputElement,
    };
  },
});
