










import { defineComponent } from 'vue';

import { appStoreMapper } from 'src/store/appstore';

export default defineComponent({
  name: 'PanelmenuNavbarButton',
  computed: {
    showIcon(): boolean {
      return (
        !this.appSettings.hideMenu &&
        (!this.miniapp || (this.miniapp && !this.appSettings.hideMenuMiniapp))
      );
    },
    ...appStoreMapper.mapGetters(['miniapp', 'appSettings']),
  },
});
