










import Vue from 'vue';

import { ButtonType, IButton } from '../types';
import { homeStoreMapper } from '../store';

import BranchList from '@/components/branch-list.vue';

export default Vue.extend({
  name: 'HomeContacts',
  components: { BranchList },
  computed: {
    pageTitle(): string {
      const callButton = this.buttons.find((button) => button.type === ButtonType.phone);

      if (callButton) {
        return callButton.title;
      }

      return this.$t('modules.home.call.title').toString();
    },
    buttons(): IButton[] {
      return this.settings.buttons || [];
    },
    ...homeStoreMapper.mapGetters(['settings']),
  },
});
