

















import Vue, { PropType } from 'vue';

import { UserPush } from '../types';

import { timeZone } from '@/utils';
import { appStoreMapper } from '@/store/appstore';
import bus from '@/bus';
import {
  IAdditionalData,
  OSNotificationOpenedResult,
} from '@/modules/notifications/dist/types/onesignal';

export default Vue.extend({
  name: 'NotificationsItem',
  props: {
    push: {
      type: Object as PropType<UserPush>,
      required: true,
    },
  },
  computed: {
    time(): string {
      return timeZone(this.push.date, {
        locale: this.locale,
        type: 'timeShort',
        timezone: this.timeZoneOptions,
      });
    },
    ...appStoreMapper.mapGetters(['timeZoneOptions']),
    ...appStoreMapper.mapState(['locale', 'domain']),
  },
  methods: {
    onPushClick() {
      const additionalData: Nullable<IAdditionalData> =
        this.push.connectedEntity && this.push.idConnectedEntity
          ? {
              connectedDomain: this.domain,
              connectedEntity: this.push.connectedEntity,
              idConnectedEntity: this.push.idConnectedEntity,
            }
          : null;

      bus.$emit('notifications-emulate-push', {
        action: {
          type: 0,
        },
        notification: {
          title: this.push.name,
          body: this.push.text,
          additionalData,
        },
      } as OSNotificationOpenedResult);
    },
  },
});
