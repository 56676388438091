import Vue from 'vue';
import { Mutations } from 'vuex-smart-module';

import { Tags } from '../types/onesignal';

import NotificationsState from './state';

export default class NotificationsMutations extends Mutations<NotificationsState> {
  setCurrentDomain(domain: string) {
    this.state.currentDomain = domain;
  }

  setTag({ key, value }: Tags) {
    Vue.set(this.state.tags, key, value);
  }

  setTags(tags: Tags) {
    Object.entries(([key, value]: [string, string]) => {
      if (!value) {
        Vue.delete(this.state.tags, key);
      } else {
        Vue.set(this.state.tags, key, value);
      }
    });

    this.state.tags = tags;
  }

  deleteAllTags() {
    this.state.tags = {};
  }
}
