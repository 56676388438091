import { Context, Getters } from 'vuex-smart-module';
import { Store } from 'vuex';

import { IBranch, IService } from '@/types';
import { IModuleSettings } from '../types';

import AppStore from '@/store/appstore';
import BookingState from './state';

export default class BookingGetters extends Getters<BookingState> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  get settings(): IModuleSettings {
    return this.app.state.appData?.settings?.modules?.booking || {};
  }

  get branches(): IBranch[] {
    return this.app.state.appData?.branches || [];
  }

  get services(): IService[] {
    return this.app.state.appData?.services || [];
  }
}
