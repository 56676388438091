import { IModule } from '@/types';

import Vue from 'vue';
import { registerModule } from 'vuex-smart-module';

import { faHistory as falWallet } from '@fortawesome/pro-light-svg-icons';
import {
  faFilter,
  faWallet as farWallet,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import * as Misc from 'src/misc';
import config from './history.conf.json';

import store from 'src/store';
import HistoryStore from './store';

import History from './components/History.vue';
import HistoryList from './components/HistoryList.vue';
import HistoryListItem from './components/HistoryListItem.vue';
import HistoryBody from './components/HistoryBody.vue';

library.add(faCheckCircle, faChevronRight, faFilter, falWallet, farWallet);

export function init() {
  Vue.component('history', History);
  Vue.component('history-list', HistoryList);
  Vue.component('history-list-item', HistoryListItem);
  Vue.component('history-body', HistoryBody);

  const routes = [
    {
      component: History,
      path: config.url,
    },
    {
      component: HistoryBody,
      path: '/history/body/:id',
    },
  ];

  registerModule(store, ['history'], 'history/', HistoryStore);

  Misc.registerModuleConfig(config as IModule);
  Misc.registerModuleRoutes(routes);
}
