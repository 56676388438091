import { Tags } from '../types/onesignal';

export async function getTags(): Promise<Tags> {
  return new Promise((resolve) => {
    if (window?.plugins?.OneSignal && !window.devMode) {
      if (window.devMode) {
        console.log('getTags', {});
      }

      window.plugins.OneSignal.getTags(resolve);
    } else {
      setTimeout(() => {
        if (window.devMode) {
          console.log('getTags', {});
        }

        resolve({});
      }, 50);
    }
  });
}

export function setTag(key: string, value: string): Promise<Tags> {
  return new Promise((resolve) => {
    if (window.devMode) {
      console.log('setTag', { [key]: value });
    }

    window.plugins?.OneSignal?.sendTag(key, value);

    resolve({ [key]: value });
  });
}

export function setTags(tags: Tags): Promise<Tags> {
  return new Promise((resolve) => {
    if (window.devMode) {
      console.log('setTags', tags);
    }

    window.plugins?.OneSignal?.sendTags(tags);

    resolve(tags);
  });
}

export function deleteTags(tags: string[]): Promise<string[]> {
  return new Promise((resolve) => {
    if (window.devMode) {
      console.log('deleteTags', tags);
    }

    window.plugins?.OneSignal?.deleteTags(tags);

    resolve(tags);
  });
}

export function deleteAllTags(): Promise<string[]> {
  const allTags: string[] = [];

  return getTags().then((tags) => {
    Object.keys(tags).forEach((tag) => {
      allTags.push(tag);
    });

    if (window.devMode) {
      console.log('deleteAllTags', Object.keys(tags));
    }

    window.plugins?.OneSignal?.deleteTags(allTags);

    return Promise.resolve(Object.keys(tags));
  });
}
