
import { defineComponent, h } from 'vue';
import { f7 } from 'framework7-vue';

import config from '../related.conf.json';
import { useRelatedStore } from '../store';
import bus from '@/bus';
import i18n from '@/translate/lang';

export default defineComponent({
  name: 'RelatedMenuItem',
  render() {
    const relatedStore = useRelatedStore();

    return h(
      'f7-list-item',
      {
        slot: 'list',
        props: {
          title: relatedStore.getters.settings.title || i18n.t(`modules.${config.name}.title`),
        },
        on: {
          click: () => {
            f7.panel.close();

            bus.$emit('relatedPopupShow');
          },
        },
      },
      [
        h('f7-icon', {
          props: {
            icon: config.menuIcon,
          },
          slot: 'media',
        }),
      ],
    );
  },
});
