








import Vue from 'vue';

import Store from 'src/storage';
import { appStoreMapper } from '@/store/appstore';
import { LOCALSTORAGE_PWA_DEBUG } from '@/misc';

export default Vue.extend({
  name: 'PWADebug',
  created() {
    this.setDebugPWA(Store.getSync(LOCALSTORAGE_PWA_DEBUG) || false);
  },
  computed: {
    show(): boolean {
      return this.isPWA && window.devMode;
    },
    ...appStoreMapper.mapGetters(['isPWA']),
    ...appStoreMapper.mapState(['debugPWA']),
  },
  methods: {
    onClick() {
      this.setDebugPWA(!this.debugPWA);
    },
    ...appStoreMapper.mapMutations(['setDebugPWA']),
  },
});
