


import {
  INotificationReceivedEvent,
  IPushMock,
  OSNotificationOpenedResult,
  Tags,
} from '../types/onesignal';

import { defineComponent, onUnmounted, onMounted } from 'vue';

import * as OS from '../utils';
import { showMessageDialog } from 'src/misc';
import bus from 'src/bus';
import { useAppStore } from '@/store/appstore';
import { useNotificationsStore } from '../store';
import { useRelatedStore } from '@/modules/related/dist/store';

export default defineComponent({
  name: 'Notifications',
  setup() {
    const appStore = useAppStore();
    const notificationsStore = useNotificationsStore();

    const relatedStore = useRelatedStore();

    onMounted(() => {
      initOneSignal();

      bus.$on('authComplete', initOneSignal);
      bus.$on('authAfterLoginSuccess', initOneSignal);
      bus.$on('authAfterRegisterSuccess', initOneSignal);
      bus.$on('notifications-emulate-push', notificationOpenedCallback);

      window.push = ({ title, body, ...additionalData }: IPushMock) => {
        notificationOpenedCallback({
          action: {
            type: 0,
          },
          notification: {
            title,
            body,
            additionalData,
            actionButtons: additionalData.actionButtons || [],
          },
        } as any);
      };
    });

    onUnmounted(() => {
      bus.$off('authComplete', initOneSignal);
      bus.$off('authAfterLoginSuccess', initOneSignal);
      bus.$off('authAfterRegisterSuccess', initOneSignal);
      bus.$off('notifications-emulate-push', notificationOpenedCallback);
    });

    async function initOneSignal() {
      document.addEventListener('deviceready', () => {
        window.plugins.OneSignal.setAppId?.(
          notificationsStore.getters.settings.onesignal,
        );

        if (!window.OS) {
          window.plugins.OneSignal.setNotificationOpenedHandler?.(
            notificationOpenedCallback,
          );
          window.plugins.OneSignal.setNotificationWillShowInForegroundHandler?.(
            notificationReceivedCallback,
          );

          window.plugins.OneSignal.promptForPushNotificationsWithUserResponse(function (
            accepted: unknown,
          ) {
            console.log('User accepted notifications: ' + accepted);
          });

          window.OS = OS;
        }

        const tags = {
          [`${appStore.state.domain}-idDevice`]: appStore.state.idDevice,
          [`${appStore.state.domain}-idUser`]: appStore.getters.user?.idUser,
          currentDomain: appStore.state.domain,
        } as Tags;

        if (!appStore.getters.user?.idUser) {
          const related: any[] = relatedStore.getters.list;

          if (Array.isArray(related)) {
            related.forEach((item) => {
              if (item.domain && item.domain !== appStore.state.domain) {
                tags[`${item.domain}-idUser`] = '0';
              }
            });
          }
        }

        notificationsStore.dispatch('setTags', tags);
      });
    }

    function actionNotificationHandler(data: OSNotificationOpenedResult) {
      bus.$emit(data.action.actionID!, data);

      notificationsStore.dispatch('notificationOpened', data);
    }

    function defaultNotificationHandler(data: OSNotificationOpenedResult) {
      const currentDomain = appStore.state.domain;
      const pushDomain = data.notification.additionalData?.connectedDomain;
      const pushEntity = data.notification.additionalData?.connectedEntity;

      if (
        relatedStore.getters.moduleEnabled &&
        pushDomain &&
        currentDomain !== pushDomain
      ) {
        if (pushEntity) {
          notificationsStore.dispatch('notificationOpened', data);
        }

        bus.$emit('selectDomain', {
          domain: pushDomain,
          callback: () => {
            defaultNotificationHandler(data);
          },
          confirm: false,
        });
      } else {
        if (pushDomain) {
          bus.$emit(`push-with-domain`);
        }

        if (pushEntity) {
          bus.$emit(`${pushEntity}-push`, data);
        } else {
          showMessageDialog({
            title: data.notification.title,
            text: data.notification.body,
            buttons: data.notification.actionButtons,
          }).then(() => {
            notificationsStore.dispatch('notificationOpened', data);
          });
        }
      }
    }

    function notificationOpenedCallback(data: OSNotificationOpenedResult) {
      const currentDomain = appStore.state.domain;
      const pushDomain = data.notification.additionalData?.connectedDomain;
      const ignoreIfNotInDomain =
        data.notification.additionalData?.ignoreIfNotInDomain === 'true';

      if (ignoreIfNotInDomain && currentDomain !== pushDomain) {
        return;
      }

      const handler = data.action.actionID
        ? actionNotificationHandler
        : defaultNotificationHandler;

      if (appStore.state.splashVisible) {
        bus.$once('splashHide', () => {
          setTimeout(() => {
            handler(data);
          }, 500);
        });
      } else {
        handler(data);
      }
    }

    function notificationReceivedCallback(event: INotificationReceivedEvent) {
      const currentDomain = appStore.state.domain;
      const pushDomain = event.notification.additionalData?.connectedDomain;
      const ignoreIfNotInDomain =
        event.notification.additionalData?.ignoreIfNotInDomain === 'true';

      if (ignoreIfNotInDomain && currentDomain !== pushDomain) {
        return;
      }

      //TODO: connectedEntity === 'bonus' is temporary workaround, need refactor
      const silent =
        event.notification.additionalData?.connectedEntity === 'bonus' ||
        !!event.notification.additionalData?.silent;

      if (silent) {
        event.complete();

        notificationsStore.dispatch('notificationReceived', event.notification);
        notificationOpenedCallback({
          notification: event.notification,
          ...{ action: { type: 0 } },
        });
      } else {
        event.complete(event.getNotification());
      }
    }

    return {};
  },
});
