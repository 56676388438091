import { IModule } from 'src/types';

import Vue from 'vue';
import { registerModule } from 'vuex-smart-module';

import { faCog, faBars } from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import * as Misc from 'src/misc';
import config from './panelmenu.conf.json';

import store from 'src/store';
import PanelmenuStore from './store';

import PanelMenu from './components/panelmenu.vue';
import PanelMenuList from './components/panelmenu-list.vue';
import PanelMenuNavbarButton from './components/panelmenu-navbar-button.vue';
import PanelMenuSlotTop from './components/panelmenu-slot-top.vue';
import PanelMenuTabbarItem from './components/panelmenu-tabbar-item.vue';

library.add(faCog, faTimes, faBars);

export function init() {
  Vue.component('panelmenu', PanelMenu);
  Vue.component('panelmenu', PanelMenu);
  Vue.component('panelmenu-list', PanelMenuList);
  Vue.component('panelmenu-navbar-button', PanelMenuNavbarButton);
  Vue.component('panelmenu-slot-top', PanelMenuSlotTop);
  Vue.component('panelmenu-tabbar-item', PanelMenuTabbarItem);

  const routes = [
    {
      component: Vue.component('panelmenu'),
      path: config.url,
    },
  ];

  registerModule(store, ['panelmenu'], 'panelmenu/', PanelmenuStore);

  Misc.registerModuleConfig(config as IModule);
  Misc.registerModuleRoutes(routes);
}
