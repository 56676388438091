


















import { defineComponent } from 'vue';

import { appStoreMapper } from 'src/store/appstore';
import { pagesStoreMapper } from '../store';

import ContentBlock from 'src/components/content.vue';

export default defineComponent({
  name: 'PageBody',
  components: { ContentBlock },
  computed: {
    currentPageContent(): string {
      return this.currentPage?.content || '';
    },
    opened(): boolean {
      return !!this.currentPage;
    },
    title(): Nullable<string> {
      return this.currentPage?.title;
    },
    ...appStoreMapper.mapGetters(['miniappHideElements']),
    ...appStoreMapper.mapState(['domain']),
    ...pagesStoreMapper.mapState(['currentPage']),
  },
  methods: {
    onPopupClosed() {
      this.setCurrentPage(null);
    },
    ...pagesStoreMapper.mapActions(['getPages']),
    ...pagesStoreMapper.mapMutations(['setCurrentPage']),
  },
  watch: {
    domain: {
      handler() {
        this.getPages();
      },
      immediate: true,
    },
  },
});
