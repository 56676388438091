

























import Vue from 'vue';

import { openUrl } from 'src/misc';
import { appStoreMapper } from 'src/store/appstore';
import { panelmenuStoreMapper } from '../store';

import PanelmenuList from './panelmenu-list.vue';
import PanelmenuSlotTop from './panelmenu-slot-top.vue';
import PanelmenuNavbarSlotRight from './panelmenu-navbar-slot-right.vue';

export default Vue.extend({
  name: 'PanelMenu',
  components: {
    PanelmenuList,
    PanelmenuSlotTop,
    PanelmenuNavbarSlotRight,
  },
  computed: {
    link(): string {
      let link =
        this.appSettings.gmbLink ??
        'https://getmeback.ru?utm_source=app&utm_content={{appName}}';

      const params = link.match(/({{.+?}})/gi);

      params?.forEach((param: string) => {
        const paramName = param.replace(/{|}/gi, '');

        link = link.replace(
          param,
          // @ts-ignore
          this.appSettings[paramName] ?? this.$store.getters[paramName] ?? '',
        );
      });

      return link;
    },
    ...appStoreMapper.mapGetters(['appSettings']),
    ...appStoreMapper.mapState(['locale']),
    ...panelmenuStoreMapper.mapGetters(['settings']),
  },
  methods: {
    goToGMB(e: Event) {
      openUrl(this.link, '_system', e);
    },
  },
});
