




import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'BonusIcon',
  props: {
    size: {
      type: [String, Number] as PropType<string | number>,
      default: () => '20',
    },
  },
});
