
import { defineComponent, ref, h, onBeforeMount, useCssModule } from 'vue';
import { f7ready, f7 } from 'framework7-vue';

import { slotSeeker } from '@/utils';

import UpdateScreen from './UpdateScreen.vue';

export default defineComponent({
  name: 'WelcomeScreen',
  setup(props, { slots }) {
    const styles = useCssModule();
    const welcomeScreenOpened = ref<boolean>(false);

    onBeforeMount(() => {
      f7ready(() => {
        f7.on('loginScreenOpen', () => {
          welcomeScreenOpened.value = true;
        });
        f7.on('loginScreenClosed', () => {
          welcomeScreenOpened.value = false;
        });
      });
    });

    return () =>
      h(
        'f7-login-screen',
        {
          props: {
            opened: welcomeScreenOpened.value,
          },
          staticClass: 'welcome-screen',
          class: [styles.loginscreen],
        },
        [
          h(
            'f7-page',
            {
              props: {
                loginScreen: true,
              },
            },
            [h(UpdateScreen), slotSeeker('welcome-screen-slot') as any, slots.default],
          ),
        ],
      );
  },
});
