import { Mutations } from 'vuex-smart-module';

import { IPage } from '../types';

import PagesState from '@/modules/pages/dist/store/state';

export default class PagesMutations extends Mutations<PagesState> {
  setCurrentPage(page: Nullable<IPage>) {
    this.state.currentPage = page;
  }

  setPages(pages: IPage[]) {
    this.state.pages = pages;
  }
}
