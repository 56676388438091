

















import Vue, { PropType } from 'vue';

import StarRating from 'vue-star-rating';

export default Vue.extend({
  name: 'FeedbackWidget',
  components: { StarRating },
  props: {
    rating: {
      type: Number as PropType<number>,
      required: true,
    },
    text: {
      type: String as PropType<string>,
      required: true,
    },
  },
});
