import { Mutations } from 'vuex-smart-module';

import { IHistoryItem } from '../types';

import HistoryState from './state';

export default class HistoryMutations extends Mutations<HistoryState> {
  pushHistory(list: IHistoryItem[]) {
    this.state.history = this.state.history.concat(list);
  }

  reset() {
    const s = new HistoryState();
    Object.keys(s).forEach((key) => {
      // @ts-ignore
      this.state[key] = s[key];
    });
  }

  setAfterPromise(value: Promise<any> | null) {
    this.state.loadingHistoryAfterPromise = value;
  }

  setHistory(list: IHistoryItem[]) {
    this.state.history = list;
  }

  setLoadingHistory(value: boolean) {
    this.state.loadingHistory = value;
  }

  setLoadingHistoryAfter(value: boolean) {
    this.state.loadingHistoryAfter = value;
  }

  setTriggerHistoryUpdate(value: boolean) {
    this.state.triggerHistoryUpdate = value;
  }

  setPromise(value: Promise<any> | null) {
    this.state.loadingHistoryPromise = value;
  }
}
