import { Module, createMapper, createComposable } from 'vuex-smart-module';

import BookingState from './state';
import BookingGetters from './getters';
import BookingMutations from './mutations';
import BookingActions from './actions';

const module = new Module({
  actions: BookingActions,
  getters: BookingGetters,
  mutations: BookingMutations,
  namespaced: true,
  state: BookingState,
});

export default module;

export const bookingStoreMapper = createMapper(module);
export const useBookingStore = createComposable(module);
