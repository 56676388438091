import { IModuleSettings } from '../types';

import { Store } from 'vuex';

import {
  Getters,
  Mutations,
  Actions,
  Module,
  Context,
  createMapper,
} from 'vuex-smart-module';

import AppStore from 'src/store/appstore';

export class PanelmenuState {}

class PanelmenuGetters extends Getters<PanelmenuState> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  get settings(): IModuleSettings {
    return this.app.state.appData?.settings?.modules?.panelmenu || {};
  }
}

class PanelmenuMutations extends Mutations<PanelmenuState> {}

class PanelmenuActions extends Actions<
  PanelmenuState,
  PanelmenuGetters,
  PanelmenuMutations,
  PanelmenuActions
> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }
}

const module = new Module({
  actions: PanelmenuActions,
  getters: PanelmenuGetters,
  mutations: PanelmenuMutations,
  namespaced: true,
  state: PanelmenuState,
});

export default module;

export const panelmenuStoreMapper = createMapper(module);
