
import { defineComponent, h } from 'vue';

import { slotSeeker } from '@/utils';

export default defineComponent({
  name: 'NavbarSlotLeft',
  render() {
    return h('f7-nav-left', {}, [slotSeeker('navbar-slot-left'), this.$slots.default]);
  },
});
