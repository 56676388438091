



















import Vue from 'vue';

import { IUser } from '@/types';

import { authStoreMapper } from '@/modules/auth/dist/store';
import { appStoreMapper } from '@/store/appstore';
import * as API from '../api';

export default Vue.extend({
  name: 'AuthSettingsSlot',
  data: () => ({
    emailReceiptActive: false,
  }),
  created() {
    this.emailReceiptActive = !!this.user?.sendReceiptToEmail;
  },
  computed: {
    emailReceiptAvailability(): boolean {
      return (
        this.isAuthorized &&
        this.settings.receiptToEmailModuleEnabled &&
        this.settings.email
      );
    },
    emailReceiptNeedEmail(): boolean {
      return !this.user?.email;
    },
    ...appStoreMapper.mapGetters(['user']),
    ...authStoreMapper.mapGetters(['isAuthorized', 'settings']),
  },
  methods: {
    onEmailReceiptClick() {
      if (this.emailReceiptNeedEmail) {
        return false;
      }

      this.emailReceiptActive = !this.emailReceiptActive;

      API.setReceiptSaving({ state: this.emailReceiptActive })
        .then((response) => {
          const user = JSON.parse(JSON.stringify(this.user)) as IUser;
          user.sendReceiptToEmail = response.value;
          this.setUser(user);
        })
        .catch((e) => {
          this.emailReceiptActive = !this.emailReceiptActive;
        });
    },
    ...appStoreMapper.mapMutations(['setUser']),
  },
  watch: {
    user: {
      handler() {
        this.emailReceiptActive = !!this.user?.sendReceiptToEmail;
      },
      immediate: true,
    },
  },
});
