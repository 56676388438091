





























import { defineComponent } from 'vue';
import { f7 } from 'framework7-vue';

import bus from 'src/bus';

import FeedbackForm from './feedback-form.vue';
import FeedbackSheetSlot from './feedback-sheet-slot.vue';

export default defineComponent({
  name: 'FeedbackPopup',
  components: { FeedbackSheetSlot, FeedbackForm },
  data: () => ({
    entityId: null as Nullable<number>,
    sheetHeight: 0,
    showAfterFeedback: false,
  }),
  created() {
    bus.$on('feedback:sheet:show', (idEntity: number) => {
      this.entityId = idEntity;
      f7.sheet.open('.feedback-sheet');
    });

    bus.$on('messageClosed', () => {
      (this.$refs.sheet as any).close();
    });
  },
  methods: {
    onSheetClose() {
      bus.$emit('hideMessage');
    },
    onSheetClosed() {
      (this.$refs.form as any)?.resetState();
      this.showAfterFeedback = false;
    },
    onSheetOpened() {
      this.sheetHeight = (
        (this.$refs.sheet as unknown as Vue).$el as HTMLElement
      ).offsetHeight;
    },
    onFeedbackShare() {
      this.showAfterFeedback = true;
    },
  },
});
