import { h } from 'vue';
import { VNode, VNodeData } from 'vue/types/vnode';

import store from '@/store';

const defaultModules = ['auth', 'bonus', 'messages'];

type Slot = [string, number];
type RawSlot = string | Slot;

export default function slotSeeker(slotName: string, data?: VNodeData): VNode[] {
  const components: VNode[] = [];
  const slots: Slot[] = [];

  window.modules.forEach((module) => {
    const slotWanted = module[slotName] as RawSlot[];

    if (
      slotWanted &&
      (store.state.appData.settings?.modules?.[module.name] != null ||
        defaultModules.includes(module.name))
    ) {
      slotWanted.forEach((slot) => {
        slots.push([
          typeof slot === 'string' ? slot : slot[0],
          typeof slot === 'string' ? 99 : slot[1],
        ]);
      });
    }
  });

  slots
    .sort(function (a, b) {
      return a[1] - b[1];
    })
    .forEach((slot) => {
      components.push(h(slot[0], data));
    });

  return components;
}
