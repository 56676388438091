import Storage from '@/storage';

import { LOCALSTORAGE_CURRENT_DOMAIN } from '../const';

import { Tags } from '../types/onesignal';

export default class NotificationsState {
  currentDomain: string = Storage.getSync(LOCALSTORAGE_CURRENT_DOMAIN) || '';
  tags: Tags = {};
}
