
import { VNode } from 'vue';
import { h } from 'vue';

import config from 'src/app.config.js';
import store from '@/store';
import { checkModuleIsActive } from '@/misc';
import { slotSeeker } from '@/utils';
import { useAppStore } from '@/store/appstore';

const defaultSlots = ['bonus-home-slot-button'];

export default {
  functional: true,
  render(context: any) {
    const appStore = useAppStore();

    let components: VNode[] = [];

    if (config.type === 'client') {
      const slots = appStore.state.appData.settings.slots;

      if (slots) {
        slots?.['home']?.['home-slot-top']?.forEach((component: string) => {
          if (checkModuleIsActive(component, store)) {
            components.push(h(component, { key: `${+new Date()}-${component}` }));
          }
        });
      } else {
        defaultSlots.forEach((component: string) => {
          if (context.parent.$options.components[component]) {
            components.push(h(component));
          }
        });
      }
    } else {
      components = slotSeeker('home-slot-top');
    }

    return components;
  },
};
