import { ActivateCodeResponse, IActivateCodeRequest } from '../types';

import * as API from 'src/api';

const API_ENDPOINT_CODE = 'activate-code';

export async function activateCode(
  data: IActivateCodeRequest,
): Promise<ActivateCodeResponse> {
  return API.request<ActivateCodeResponse>({
    data,
    method: 'post',
    url: API_ENDPOINT_CODE,
  });
}
