import { IModule } from 'src/types';

import Vue from 'vue';
import { registerModule } from 'vuex-smart-module';

import {
  faBagsShopping,
  faCheckCircle,
  faClock,
  faTimesCircle,
  faDotCircle,
  faCartArrowDown,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faHistory,
  faStoreAlt,
  faCircleNotch,
  faInfoCircle,
  faLocationArrow,
  faTrashAlt,
  faChevronRight,
  faChevronDown,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faMinus,
  faPlus,
  faTimes,
  faMinusSquare,
  faPlusSquare,
} from '@fortawesome/pro-light-svg-icons';
import {
  faGifts,
  faBagsShopping as faBagsShoppingDuo,
} from '@fortawesome/pro-duotone-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import * as Misc from 'src/misc';
import config from './catalog.conf.json';

import store from 'src/store';
import CatalogStore from './store';

import Basket from './components/basket.vue';
import BasketDaemon from './components/basket-daemon.vue';
import BonusPayProduct from './components/BonusPayProduct.vue';
import BonusPayOrder from './components/BonusPayOrder.vue';
import Catalog from './components/catalog.vue';
import CatalogHomeSlot from './components/catalog-home-slot.vue';
import CatalogHomeSlotPadding from './components/CatalogHomeSlotPadding.vue';
import CatalogMenuItem from './components/catalog-menu-item.vue';
import CatalogTabbarItem from './components/catalog-tabbar-item.vue';
import Category from './components/category.vue';
import Form from './components/form.vue';
import History from './components/history.vue';
import HistoryBarHomeSlot from './components/history-bar-home-slot.vue';
import HistoryBody from './components/history-body.vue';
import ProductPopup from './components/product-popup.vue';
import CatalogTabbarSlot from './components/CatalogTabbarSlot.vue';
import DeliveryMap from './components/DeliveryMap.vue';
import DeliveryBlocker from './components/DeliveryBlocker.vue';
import CatalogSearch from './components/CatalogSearch.vue';
import CatalogItemsList from './components/CatalogItemsList.vue';

library.add(
  faBagsShopping,
  faCheckCircle,
  faClock,
  faDotCircle,
  faHistory,
  faTimesCircle,
  faStoreAlt,
  faMinus,
  faPlus,
  faGifts,
  faBagsShoppingDuo,
  faCircleNotch,
  faInfoCircle,
  faLocationArrow,
  faTrashAlt,
  faChevronRight,
  faTimes,
  faMinusSquare,
  faPlusSquare,
  faCartArrowDown,
  faChevronDown,
);

export function init() {
  Vue.component('basket-daemon', BasketDaemon);
  Vue.component('catalog-menu-item', CatalogMenuItem);
  Vue.component('catalog-tabbar-item', CatalogTabbarItem);
  Vue.component('catalog-home-slot', CatalogHomeSlot);
  Vue.component('catalog-home-slot-padding', CatalogHomeSlotPadding);
  Vue.component('history-bar', HistoryBarHomeSlot);
  Vue.component('product-popup', ProductPopup);
  Vue.component('catalog-tabbar-slot', CatalogTabbarSlot);
  Vue.component('delivery-map', DeliveryMap);
  Vue.component('delivery-blocker', DeliveryBlocker);
  Vue.component('catalog-items-list', CatalogItemsList);

  const routes = [
    {
      component: Basket,
      path: '/catalog/basket',
      name: 'Basket',
    },
    {
      component: BonusPayProduct,
      path: '/catalog/bonus-product',
      name: 'CatalogBonusProduct',
    },
    {
      component: BonusPayOrder,
      path: '/catalog/bonus-order',
      name: 'CatalogBonusOrder',
    },
    {
      component: Catalog,
      path: config.url,
      name: 'Catalog',
    },
    {
      component: Category,
      path: '/catalog/category/:categoryId',
    },
    {
      component: Form,
      path: '/catalog/form',
      name: 'CatalogForm',
    },
    {
      component: History,
      path: '/catalog/history',
    },
    {
      component: HistoryBody,
      path: '/catalog/history/body',
    },
    {
      component: CatalogSearch,
      path: '/catalog/search',
    },
  ];

  registerModule(store, ['catalog'], 'catalog/', CatalogStore);

  Misc.registerModuleConfig(config as IModule);
  Misc.registerModuleRoutes(routes);
}
