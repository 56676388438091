






































import { defineComponent, PropType, computed } from 'vue';

import getLocalizedPrice from '@/utils/getLocalizedPrice';
import { useAppStore } from '@/store/appstore';
import i18n from '@/translate/lang';

import AnimatedNumber from '@/components/AnimatedNumber.vue';

interface IOptions {
  decimalRoundingRule: decimalRoundingRule;
  decimal?: number;
}

export default defineComponent({
  name: 'CatalogBasketBar',
  components: {
    AnimatedNumber,
  },
  props: {
    amount: {
      type: Number as PropType<number>,
      required: true,
      validator: (value: number) => value >= 0,
    },
    price: {
      type: Number as PropType<number>,
      required: true,
      validator: (value: number) => value >= 0,
    },
    title: {
      type: String as PropType<string>,
    },
    duration: {
      type: Number as PropType<number>,
      default: 300,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
    },
    next: {
      type: Boolean as PropType<boolean>,
    },
    inForm: {
      type: Boolean as PropType<boolean>,
    },
    options: {
      type: Object as PropType<IOptions>,
      default: () => ({
        decimalRoundingRule: 'floor',
      }),
      validator: (options: IOptions) => {
        return (
          options.decimalRoundingRule === 'floor' ||
          options.decimalRoundingRule === 'ceil' ||
          options.decimalRoundingRule === 'default' ||
          (!!options.decimalRoundingRule && options.decimal != null)
        );
      },
    },
  },
  setup(props, { emit }) {
    const appStore = useAppStore();

    const amountFormatted = computed<string>(() =>
      props.amount
        ? `${props.amount} ${i18n.tc('modules.catalog.products.plural', props.amount)}`
        : `${i18n.t('modules.catalog.bar.amount.empty')}`,
    );
    const titleComputed = computed<string>(
      () => props.title ?? `${i18n.t('modules.catalog.basket.title')}`,
    );

    function formatPrice(value: number) {
      return getLocalizedPrice(
        value,
        appStore.getters.currency,
        props.options.decimalRoundingRule,
        props.options.decimal,
      );
    }

    function onClick() {
      emit('click');
    }

    return {
      amountFormatted,
      titleComputed,
      onClick,
      formatPrice,
    };
  },
});
