

















































































































import { defineComponent } from 'vue';
import { f7 } from 'framework7-vue';

import { IButton, ButtonType } from '../types';

import { goToModule, openUrl } from 'src/misc';
import { homeStoreMapper } from '../store';
import { sendStat } from '@/misc';
import config from '@/app.config.js';
import { appStoreMapper } from '@/store/appstore';

import HomeSlotButtonIconFeedback from './home-slot-button-icon-feedback.vue';
import HomeSlotButtonIconPhone from './home-slot-button-icon-phone.vue';

export default defineComponent({
  name: 'HomeSlotButtons',
  components: { HomeSlotButtonIconFeedback, HomeSlotButtonIconPhone },
  data: () => ({
    ButtonType,
    config,
  }),
  computed: {
    buttons(): IButton[] {
      return this.settings.buttons || [];
    },
    buttonsList(): IButton[] {
      return this.buttonsOdd ? this.buttons.slice(0, -1) : this.buttons;
    },
    buttonsOdd(): boolean {
      return !!(this.buttons.length % 2);
    },
    buttonsBlocks(): Array<IButton[]> {
      const blocks = [];

      if (this.buttons.length > 1) {
        blocks.push(this.buttonsList);
      }

      if (this.buttonsOdd) {
        blocks.push(this.buttons.slice(-1));
      }

      return blocks;
    },
    ...appStoreMapper.mapGetters(['isPWA']),
    ...homeStoreMapper.mapGetters(['settings']),
  },
  methods: {
    call(link: string, e: Event) {
      sendStat({ action: 'btn-click', id: 'phone' }, this.$store as any);
      openUrl(link, '_system', e);
    },
    goToContacts() {
      f7.views.current.router.navigate('/home/contacts');
    },
    goToFeedback() {
      sendStat({ action: 'btn-click', id: 'feedback' }, this.$store as any);
      goToModule({ name: 'feedback' });
    },
    openLink(button: IButton, link: string, e: Event) {
      sendStat({ action: 'btn-click', id: button.type }, this.$store as any);
      openUrl(link, '_system', e);
    },
  },
});
