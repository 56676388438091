import { Context, Getters } from 'vuex-smart-module';
import { Store } from 'vuex';

import { CouponWithType, GiftType, GiftWithType, IModuleSettings } from '../types';

import AppStore from '@/store/appstore';
import GiftsState from './state';

export default class GiftsGetters extends Getters<GiftsState> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  get settings(): IModuleSettings {
    const settings = this.app.state.appData.settings?.modules?.gifts || {};

    settings.openInPopup = settings.openInPopup ?? true;
    settings.itemsPerPage = settings.itemsPerPage ?? 20;

    return settings;
  }

  get itemsComputed(): Array<GiftWithType | CouponWithType> {
    const array: Array<GiftWithType | CouponWithType> = [];

    this.state.items.forEach((item) => {
      const [itemType, itemId] = item.split('-') as [GiftType, string];
      const foundItem = (
        itemType === 'gift'
          ? this.state.gifts.find((gift) => gift.id === Number(itemId))
          : this.state.coupons.find((coupon) => coupon.id === Number(itemId))
      ) as GiftWithType | CouponWithType;
      if (foundItem) {
        foundItem.type = itemType;
        array.push(foundItem);
      }
    });

    return array;
  }
}
