




import Vue from 'vue';
import { f7 } from 'framework7-vue';

export default Vue.extend({
  name: 'SettingsPanelmenuIcon',
  methods: {
    goToSettings() {
      f7.panel.close();
      f7.views.current.router.navigate('/settings/');
    },
  },
});
