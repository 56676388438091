
import Vue from 'vue';

import { slotSeeker } from '@/utils';

export default Vue.extend({
  name: 'AuthWelcomeBonusSlot',
  functional: true,
  render() {
    return slotSeeker('auth-welcome-bonus-slot');
  },
});
