




































































import { IRegistrationRequest } from '../types';
import { AuthType } from 'src/types/enum';

import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
import { f7 } from 'framework7-vue';

import { useAppStore } from 'src/store/appstore';
import { useAuthStore } from '../store';
import { showErrorDialog } from 'src/misc';
import { getFormattedDate } from '../misc';

import useValidationState from 'src/validators/validation-state';

import ListInputDate from 'src/components/fields/list-input-date.vue';
import ListInputName from 'src/components/fields/list-input-name.vue';
import ListInputPassword from 'src/components/fields/list-input-password.vue';
import ListInputSurname from 'src/components/fields/list-input-surname.vue';
import ListInputEmail from 'src/components/fields/list-input-email.vue';

export default defineComponent({
  name: 'ProfileEdit',
  components: {
    ListInputDate,
    ListInputName,
    ListInputPassword,
    ListInputSurname,
    ListInputEmail,
  },
  props: {
    callback: Function as PropType<Nullable<() => void>>,
  },
  setup(props) {
    const { validationState, onStateChanged, touchAll } = useValidationState();
    const refForm = ref<HTMLElement>(null as unknown as any);
    const isDirty = computed<boolean>(() => validationState.dirty);

    const appStore = useAppStore();
    const authStore = useAuthStore();

    const name = ref<string>(appStore.getters.user?.fName ?? '');
    const surname = ref<string>(appStore.getters.user?.lName ?? '');
    const birthday = ref<string | Date>(appStore.getters.user?.bday ?? '');
    const password = ref<string>('');
    const email = ref<string>(appStore.getters.user?.email ?? '');
    const birthdayFormatted = getFormattedDate(birthday.value as any);

    const buttonClicked = ref<boolean>(false);

    if (
      typeof birthdayFormatted === 'string' &&
      !appStore.getters.appSettings.birthdayUseSystemDatePicker
    ) {
      birthday.value = birthdayFormatted.split('-').reverse().join('.');
    } else if (
      typeof birthdayFormatted !== 'string' &&
      Number.isNaN(birthdayFormatted.getTime())
    ) {
      birthday.value = birthdayFormatted;
    }

    onMounted(() => {
      if (props.callback) {
        const emailInput = refForm.value.querySelector(
          '[data-type=email] input',
        ) as HTMLInputElement;

        if (emailInput) {
          emailInput.focus();
        }
      }
    });

    function save() {
      buttonClicked.value = true;

      if (validationState.invalid) {
        touchAll();

        const invalidInput = refForm.value.querySelector(
          '.item-input-invalid input',
        ) as HTMLInputElement;

        if (invalidInput) {
          invalidInput.focus();
        }

        return;
      }

      const params: IRegistrationRequest = {
        display_name: (name.value + ' ' + surname.value).trim(),
        bDate: getFormattedDate(birthday.value as any) || '',
        password: password.value,
        email: email.value,
      };

      f7.preloader.show();

      authStore
        .dispatch('updateUser', params)
        .then(() => {
          f7.views.current.router.back();
        })
        .catch((error) => {
          showErrorDialog({ error });
        })
        .finally(() => {
          f7.preloader.hide();

          if (props.callback) {
            props.callback();
          }
        });
    }

    return {
      refForm,
      AuthType,
      validationState,
      onStateChanged,
      isDirty,
      buttonClicked,
      save,
      appSettings: appStore.getters.appSettings,
      settings: authStore.getters.settings,
      name,
      surname,
      birthday,
      password,
      email,
    };
  },
});
