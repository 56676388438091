import { Context, Getters } from 'vuex-smart-module';
import { Store } from 'vuex';

import { IModuleSettings, ISpecialOffer } from '@/modules/specialoffer/dist/types';

import AppStore from '@/store/appstore';
import SpecialOfferState from './state';

export default class SpecialOfferGetters extends Getters<SpecialOfferState> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  get settings(): IModuleSettings {
    const settings =
      this.app.state.appData?.settings?.modules?.specialoffer || ({} as IModuleSettings);

    settings.showTitle = settings.showTitle ?? true;
    settings.showLabels = settings.showLabels ?? true;

    return settings;
  }

  get offers(): ISpecialOffer[] {
    return this.getters.settings.list || [];
  }
}
