









































































import { defineComponent, PropType } from 'vue';
import { f7 } from 'framework7-vue';

import { IBasketEntry, IProduct, ProductAmount } from '../types';

import config from 'src/app.config.js';
import { appStoreMapper } from 'src/store/appstore';
import { catalogStoreMapper } from '../store';
import { getLocalizedPrice } from '@/utils';

export default defineComponent({
  name: 'BasketEntry',
  props: {
    entry: {
      type: Object as PropType<IBasketEntry>,
      required: true,
    },
    options: {
      type: Object as PropType<IDecimalOptions>,
      required: true,
    },
  },
  mounted() {
    (this.$refs.swipeout as HTMLElement).addEventListener('swipeout:deleted', () => {
      this.removeEntry(this.product);
    });
  },
  computed: {
    amount(): number {
      return this.entry.amount;
    },
    image(): string | null {
      return this.entry.product.images.length
        ? `${config.api.protocol}://${this.clientName}.${config.api.host}/rest/image/${
            this.entry.product.images[0]
          }/0/${Math.floor(window.innerWidth / 4)}/scale.png`
        : null;
    },
    product(): IProduct {
      return this.entry.product;
    },
    isPlusDisabled(): boolean {
      const amount: Nullable<ProductAmount> = this.settings.amountControl
        ? this.entry.product.amounts?.find(
            (entry: ProductAmount) => entry.branch_id === this.receiveBranchId,
          ) ?? 0
        : undefined;
      return !!(amount && this.amount >= amount.amount);
    },
    priceFormatted(): string {
      return getLocalizedPrice(
        this.product.price,
        this.currency,
        this.options.decimalRoundingRule,
        this.options.decimal,
        {
          useMils: true,
        },
      );
    },
    ...appStoreMapper.mapGetters(['clientName', 'currency']),
    ...catalogStoreMapper.mapGetters(['settings', 'receiveBranchId']),
  },
  methods: {
    del() {
      f7.dialog.confirm(
        this.$i18n.t('modules.catalog.basket.list.dialog.delete.text').toString(),
        this.$i18n.t('modules.catalog.basket.list.dialog.delete.title').toString(),
        () => {
          this.removeEntry(this.product);
        },
      );
    },
    minus() {
      this.updateProduct({ amount: -1, product: this.product });
    },
    plus() {
      this.updateProduct({ amount: 1, product: this.product });
    },
    ...catalogStoreMapper.mapMutations(['removeEntry', 'updateProduct']),
  },
});
