
import { defineComponent } from 'vue';

import bus from 'src/bus';
import { VNode, CreateElement } from 'vue';
import { getModule } from 'src/misc';
import { appStoreMapper } from 'src/store/appstore';

export default defineComponent({
  name: 'Tabbar',
  render(this: any, h: CreateElement) {
    return this.showTabbar
      ? h(
          'f7-toolbar',
          {
            props: {
              tabbar: true,
              labels: true,
              bottom: true,
              'no-shadow': true,
              hidden: false,
            },
            ref: 'tabbar',
            class: {
              'hidden-labels': this.appSettings.hideTabbarLabels,
            },
            on: {
              'toolbar:hide': () => {
                bus.$emit('tabbar:hide');
              },
              'toolbar:show': () => {
                bus.$emit('tabbar:show');
              },
            },
          },
          getTabs.call(this, h),
        )
      : h(undefined);
  },
  computed: {
    ...appStoreMapper.mapGetters(['appSettings', 'defaultTab', 'showTabbar']),
    ...appStoreMapper.mapState(['appData', 'currentTab']),
  },
  watch: {
    currentTab(module: string) {
      const tabbarModules: string[] = this.appData.settings?.tabbar || [];

      if (!tabbarModules.includes(module)) {
        const activeLink = this.$el.querySelector('.tab-link-active');

        if (activeLink) {
          activeLink.classList.remove('tab-link-active');
        }
      }

      // iOS 14 fix
      const tabbarEl = (this.$refs.tabbar as unknown as Vue).$el as HTMLElement;

      tabbarEl.style.opacity = '0.999';

      setTimeout(() => {
        tabbarEl.style.opacity = '1';
      }, 50);
    },
  },
});

function getTabs(this: any, h: CreateElement): VNode[] {
  const tabbarModules: string[] = this.appData.settings?.tabbar || [];
  let tabs: VNode[] = [];

  tabbarModules.forEach((module) => {
    if (this.$store.state.appData.settings?.modules?.[module] != null) {
      const m = getModule(module);

      if (m) {
        if (m.tabbarItem) {
          tabs.push(h(m.tabbarItem, { key: `${this.$store.state.domain}-${module}` }));
        } else if (this.appData.settings?.modules?.[module]?.tab ?? true) {
          const tabbarTitle =
            this.appData.settings?.modules?.[module]?.tabbarTitle ||
            this.$t(`modules.${module}.tabbarTitle`);

          if (m.tabName) {
            tabs.push(
              h('f7-link', {
                key: `${this.$store.state.domain}-${module}`,
                class: {
                  ['tab-link-' + m.tabName]: true,
                },
                props: {
                  href: `#${m.tabName}`,
                  icon: m.tabbarIcon,
                  text: tabbarTitle,
                  'tab-link': true,
                  'tab-link-active': this.defaultTab
                    ? module === this.defaultTab
                    : module === 'home',
                },
                on: {
                  click: () => {
                    bus.$emit('tabWillOpen', module);
                  },
                },
              }),
            );
          } else {
            tabs.push(
              h('f7-link', {
                key: `${this.$store.state.domain}-${module}`,
                class: {
                  ['tab-link-' + m.tabName]: true,
                },
                props: {
                  href: m.url,
                  icon: m.tabbarIcon,
                  text: tabbarTitle,
                  'tab-link': true,
                  'tab-link-active': this.defaultTab
                    ? module === this.defaultTab
                    : module === 'home',
                },
                on: {
                  click: () => {
                    bus.$emit('tabWillOpen', module);
                  },
                },
              }),
            );
          }
        }
      }
    }
  });

  return tabs;
}
