import { IModule } from 'src/types';

import Vue from 'vue';
import { registerModule } from 'vuex-smart-module';

import { faUserCircle } from '@fortawesome/pro-solid-svg-icons';
import {
  faBars,
  faCheck,
  faPencil,
  faUser,
  faSignIn,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faUsers,
  faUserCircle as faUserCircleLight,
} from '@fortawesome/pro-light-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import * as Misc from 'src/misc';
import config from './auth.conf.json';

import store from 'src/store';
import AuthStore from './store';

import Auth from './components/auth.vue';
import AuthBlock from './components/auth-block.vue';
import AuthDaemon from './components/auth-daemon.vue';
import AuthMenuItems from './components/auth-menu-items.vue';
import AuthTabbarItem from './components/auth-tabbar-item.vue';
import AuthNavbarButton from './components/auth-navbar-button.vue';
import Registration from './components/registration.vue';
import RegistrationSlotForm from './components/registration-slot-form.vue';
import Profile from './components/profile.vue';
import ProfileEdit from './components/profile-edit.vue';
import WelcomeScreen from './components/welcome.vue';
import Password from './components/password.vue';
import Pincode from './components/pincode.vue';
import AuthWelcomeScreenSlot from './components/AuthWelcomeScreenSlot.vue';
import AuthSettingsSlot from './components/AuthSettingsSlot.vue';

library.add(
  faBars,
  faCheck,
  faPencil,
  faUser,
  faSignIn,
  faUsers,
  faUserCircle,
  faUserCircleLight,
  faChevronRight,
);

export function init() {
  Vue.component('auth-block', AuthBlock);
  Vue.component('auth-daemon', AuthDaemon);
  Vue.component('auth-menu-items', AuthMenuItems);
  Vue.component('auth-tabbar-item', AuthTabbarItem);
  Vue.component('auth-navbar-button', AuthNavbarButton);
  Vue.component('registration-slot-form', RegistrationSlotForm);
  Vue.component('auth-welcome-screen', WelcomeScreen);
  Vue.component('auth-welcome-screen-slot', AuthWelcomeScreenSlot);
  Vue.component('auth-settings-slot', AuthSettingsSlot);

  const routes = [
    {
      component: Auth,
      path: '/auth',
    },
    {
      component: Password,
      path: '/auth/password',
    },
    {
      component: Pincode,
      path: '/auth/pincode',
    },
    {
      component: Registration,
      path: '/registration',
    },
    {
      component: Profile,
      path: '/profile',
    },
    {
      component: ProfileEdit,
      path: '/profile/edit',
    },
  ];

  registerModule(store, ['auth'], 'auth/', AuthStore);

  Misc.registerModuleConfig(config as IModule);
  Misc.registerModuleRoutes(routes);
}
