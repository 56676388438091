export default function transformAppDataSettings(data: any) {
  if (!data.settings) {
    return data;
  }

  const transformedSettings: any = {};

  Object.keys(data.settings).forEach((key) => {
    const transformedKey = key.replace(/^app\./, '');

    transformedSettings[transformedKey] = data.settings[key];
  });

  data.settings = transformedSettings;

  return data;
}
