import {
  AddressDetails,
  DeliveryAddress,
  IBasketEntry,
  ICatalogHistoryItem,
  ICategory,
  IProduct,
  ReceiveType,
  SearchCache,
} from '@/modules/catalog/dist/types';
import { IBranch } from '@/types';

export default class CatalogState {
  categories: ICategory[] = [];
  basket: IBasketEntry[] = [];
  paidByBonus: IProduct[] = [];
  pickupPoint: Nullable<IBranch> = null;
  receive: Nullable<ReceiveType> = null;
  showCatalogHistoryBar: boolean = false;
  orderStatus: Nullable<ICatalogHistoryItem> = null;
  productsByCategoryId: {
    [key: number]: IProduct[];
  } = {};
  categoriesScrollTop: {
    [key: number]: number;
  } = {};
  inBasket: boolean = false;
  inForm: boolean = false;
  inBonus: boolean = false;
  lazyLoadedImagesIds: number[] = [];
  deliveryAddress: Nullable<DeliveryAddress> = null;
  deliveryAddressDetails: Nullable<AddressDetails> = null;
  deliveryAddressBranch: Nullable<number | string> = null;
  selectedHistoryItem: Nullable<ICatalogHistoryItem> = null;
  searchCache: SearchCache[] = [];
  payByBonusOrderAmount = 0;
}
