
import { defineComponent, h } from 'vue';

import { slotSeeker } from '@/utils';

export default defineComponent({
  name: 'NavbarSlotRight',
  render() {
    return h('f7-nav-right', {}, [slotSeeker('navbar-slot-right'), this.$slots.default]);
  },
});
