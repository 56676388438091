


























declare var cordova: any;
type IEnumToProp<R> = { [key in keyof typeof SocialNetwork]: R };

import { ISocialNetwork } from 'src/types';
import { SocialNetwork } from 'src/types/enum';

import { defineComponent } from 'vue';
import {
  faFacebookF,
  faInstagram,
  faTelegramPlane,
  faVk,
  faOdnoklassniki,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import { openUrl } from 'src/misc';
import { appStoreMapper } from 'src/store/appstore';

library.add(faFacebookF, faInstagram, faTelegramPlane, faVk, faOdnoklassniki, faTiktok);

export default defineComponent({
  name: 'HomeSocial',
  data: () => ({
    blocksSettings: {
      tg: {
        icon: 'fab fa-telegram-plane',
        size: 60,
      },
      insta: { icon: 'fab fa-instagram', size: 50 },
      vk: { icon: 'fab fa-vk', size: 65 },
      fb: { icon: 'fab fa-facebook-f', size: 44 },
      ok: { icon: 'fab fa-odnoklassniki', size: 44 },
      tt: { icon: 'fab fa-tiktok', size: 40 },
    } as IEnumToProp<any>,
  }),
  computed: {
    dense(): boolean {
      return !!this.socialNetworksSettings?.dense;
    },
    spaced(): boolean {
      return !!this.socialNetworksSettings?.centered || this.socialNetworks.length <= 2;
    },
    ...appStoreMapper.mapGetters(['socialNetworks', 'socialNetworksSettings']),
  },
  methods: {
    openLink(item: ISocialNetwork, e: Event) {
      openUrl(item.link, '_system', e);
    },
  },
});
