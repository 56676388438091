import { Mutations } from 'vuex-smart-module';

import BonusState from './state';

export default class BonusMutations extends Mutations<BonusState> {
  setSpentBonus(value: number) {
    this.state.spentBonus = value;
  }

  setCustomTitle(value: string) {
    this.state.customTitle = value;
  }
}
