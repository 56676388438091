import { createComposable, createMapper, Module } from 'vuex-smart-module';

import CatalogActions from './actions';
import CatalogGetters from './getters';
import CatalogMutations from './mutations';
import CatalogState from './state';

const module = new Module({
  actions: CatalogActions,
  getters: CatalogGetters,
  mutations: CatalogMutations,
  namespaced: true,
  state: CatalogState,
});

export default module;

export const catalogStoreMapper = createMapper(module);
export const useCatalogStore = createComposable(module);
