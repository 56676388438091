import { render, staticRenderFns } from "./debug.vue?vue&type=template&id=d65c26c0&scoped=true&"
import script from "./debug.vue?vue&type=script&lang=ts&"
export * from "./debug.vue?vue&type=script&lang=ts&"
import style0 from "./debug.vue?vue&type=style&index=0&id=d65c26c0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.8_wzvh6gi4tchptuexh7cjhim74e/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d65c26c0",
  null
  
)

export default component.exports