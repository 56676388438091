import { decimal, createI18nMessage } from '@vuelidate/validators';

import i18n from '@/translate/lang';

function translationFunction() {
  return i18n.t('global.validators.decimal.error').toString();
}

const withI18nMessage = createI18nMessage({ t: translationFunction });

export default withI18nMessage(decimal);
