























import Vue, { PropType } from 'vue';
import QRCode from 'qrcode-svg-ts';

import { CodeType } from '@/store/types';
import { Gift } from '../types';

import { appStoreMapper } from '@/store/appstore';
import { formatNumber } from '@/misc';

export default Vue.extend({
  name: 'GiftCodeContent',
  props: {
    gift: Object as PropType<Gift>,
    showTitle: String as PropType<string>,
  },
  data: () => ({
    qr: '',
  }),
  computed: {
    CodeType() {
      return CodeType;
    },
    innerWidth(): number {
      return this.codeType === CodeType.qr
        ? window.innerWidth - 40
        : (window.innerWidth * 2) / 3;
    },
    ...appStoreMapper.mapGetters(['codeType', 'user']),
  },
  methods: {
    formatNumber,
    generateQrCode() {
      this.qr = new QRCode({
        content: this.gift.codeQR || this.gift.code || 'invalid qr-code',
        padding: 2,
        width: (window.innerWidth * 2) / 3,
        height: (window.innerWidth * 2) / 3,
        color: '#000000',
        background: '#ffffff',
        ecl: 'L',
        container: 'svg-viewbox',
        join: true,
      }).svg();
    },
  },
  watch: {
    user: {
      handler() {
        this.generateQrCode();
      },
      deep: true,
      immediate: true,
    },
  },
});
