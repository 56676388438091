












import Vue from 'vue';
import { f7 } from 'framework7-vue';

import { refStoreMapper } from '../store';

export default Vue.extend({
  name: 'ReferralHomeSlotButton',
  computed: {
    show(): boolean {
      return this.settings.homeShowShare;
    },
    ...refStoreMapper.mapGetters(['settings']),
  },
  methods: {
    openReferral() {
      f7.views.current.router.navigate('/referral/');
    },
  },
});
