import { createComposable, createMapper, Module } from 'vuex-smart-module';

import { RefActions } from './actions';
import { RefGetters } from './getters';
import { RefMutations } from './mutations';
import { RefState } from './state';

const module = new Module({
  actions: RefActions,
  getters: RefGetters,
  mutations: RefMutations,
  namespaced: true,
  state: RefState,
});

export default module;

export const refStoreMapper = createMapper(module);
export const useRefStore = createComposable(module);
