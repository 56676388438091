function _contactPhoneFormatted(value: string): string {
  const cleaned = ('' + value).replace(/\D/g, '');
  const match = cleaned.match(/^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/);

  if (match) {
    const intlCode = match[1] ? match[1] : '7';
    const array = [
      '+',
      intlCode,
      ' ',
      '(',
      match[2],
      ') ',
      match[3],
      '-',
      match[4],
      '-',
      match[5],
    ];
    if (intlCode === '8') array.splice(0, 1);
    return array.join('');
  }

  return '+' + cleaned;
}

export const getFormattedPhone = _contactPhoneFormatted;
