import { Actions, Context } from 'vuex-smart-module';
import { Store } from 'vuex';

import {
  GetCodeResponse,
  IAuthRequest,
  IDropPasswordRequest,
  IGetCodeRequest,
  IRegisterDeviceRequest,
  IRegistrationRequest,
  RegisterDeviceResponse,
} from '../types';
import { IUser } from '@/types';

import * as API from '../api';
import bus from '@/bus';
import { TResponse } from '@/api';

import AppStore from '@/store/appstore';
import AuthGetters from './getters';
import AuthMutations from './mutations';
import AuthState from './state';

let registerDevicePromise: Nullable<Promise<RegisterDeviceResponse>> = null;

export default class AuthActions extends Actions<
  AuthState,
  AuthGetters,
  AuthMutations,
  AuthActions
> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  logout() {
    this.app.dispatch('logout', undefined);
  }

  async auth(data: IAuthRequest): Promise<IUser> {
    this.commit('setAuthPending', true);

    const params = { authToken: this.app.state.authToken, store: this.app, ...data };

    try {
      const { authToken } = await API.auth(params);

      await this.app.dispatch('login', authToken);
      await this.app.dispatch('getAppData', bus);

      return this.app.state.appData.user!;
    } finally {
      this.commit('setAuthPending', false);
    }
  }

  dropPassword(data: IDropPasswordRequest): Promise<TResponse> {
    const params = { authToken: this.app.state.authToken, ...data };

    return API.dropPassword(params);
  }

  getSmsCode(data: IGetCodeRequest): Promise<GetCodeResponse> {
    return API.getCode(data);
  }

  async registerDevice(data: IRegisterDeviceRequest) {
    if (registerDevicePromise) {
      return registerDevicePromise;
    }

    const params = {
      authToken: this.app.state.authToken,
      ...data,
    };

    return (registerDevicePromise = API.registerDevice(params)
      .then((response) => {
        this.app.commit('setIdDevice', response.user.idDevice);
        this.app.commit('setAuthToken', response.authToken);
        this.app.commit('setRegDeviceDidFail', false);
        this.app.commit('setUuid', params.key);

        return response;
      })
      .catch((e) => {
        this.app.commit('setRegDeviceDidFail', true);

        bus.$emit('regDeviceDidFail');

        return Promise.reject(e);
      })
      .finally(() => {
        registerDevicePromise = null;
      }));
  }

  async registerUser(data: IRegistrationRequest): Promise<IUser> {
    const params = { authToken: this.app.state.authToken, ...data };

    try {
      const { authToken, user } = await API.registerUser(params);

      await this.app.dispatch('login', authToken);

      if (user) {
        await this.app.dispatch('getAppData', bus);
      }

      return Promise.resolve(user);
    } catch (e) {
      throw e;
    }
  }

  async updateUser(data: IRegistrationRequest) {
    const params = { authToken: this.app.state.authToken, ...data };

    try {
      const { user } = await API.updateUser(params);
      this.app.commit('setUser', user);
    } catch (e) {
      throw e;
    }
  }
}
