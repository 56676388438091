import * as API from 'src/api';
import { IPage } from '../types';
import { IPagesResponse } from '../types';

const API_ENDPOINT_PAGES = 'pages';

export async function getPages(): Promise<IPage[]> {
  return API.request<IPagesResponse>({
    method: 'get',
    url: API_ENDPOINT_PAGES,
  }).then((response) => response.pages);
}
