import { Mutations } from 'vuex-smart-module';

import { Coupon, Gift } from '../types';

import GiftsState from './state';

export default class GiftsMutations extends Mutations<GiftsState> {
  setGifts(gifts: Gift[]) {
    this.state.gifts = gifts;
  }

  setCoupons(coupons: Coupon[]) {
    this.state.coupons = coupons;
  }

  setItems(items: string[]) {
    this.state.items = items;
  }
}
