



















import { defineComponent } from 'vue';

import { appStoreMapper } from 'src/store/appstore';
import { bonusStoreMapper } from '../store';
import { goToModule } from 'src/misc';

import BonusIcon from 'src/components/bonus-icon.vue';

export default defineComponent({
  name: 'BonusHomeSlotOverview',
  components: { BonusIcon },
  computed: {
    ...appStoreMapper.mapGetters(['miniappHideElements', 'user']),
    ...bonusStoreMapper.mapGetters(['moduleEnabled']),
  },
  methods: {
    goToHistory() {
      goToModule({ name: 'history' });
    },
  },
});
