import {
  IFeedback,
  IGetFeedbackResponse,
  ISendFeedbackRequest,
  ISendFeedbackResponse,
} from '../types';

import * as API from 'src/api';

const API_ENDPOINT_SENDFEEDBACK = 'feedback';
const API_ENDPOINT_GETFEEDBACK = 'get-feedback';

export async function sendFeedback(
  data: ISendFeedbackRequest,
): Promise<ISendFeedbackResponse> {
  return API.request<ISendFeedbackResponse>({
    data,
    method: 'post',
    url: API_ENDPOINT_SENDFEEDBACK,
  });
}

export async function getFeedback(): Promise<IFeedback[]> {
  return API.request<IGetFeedbackResponse>({
    method: 'get',
    url: API_ENDPOINT_GETFEEDBACK,
  }).then((response) => response.feedback);
}
