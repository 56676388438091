export enum SpecialOfferType {
  action = 'action',
  gift = 'gift',
  news = 'news',
  product = 'product',
}

export enum SpecialOfferListType {
  mosaic = 'mosaic',
  slider = 'slider',
}

export interface ISpecialOffer {
  type: SpecialOfferType;
  id: number;
  title: string;
  image: number;
  bonus?: number;
  price?: number;
  label?: string;
}

export interface IModuleSettings {
  list?: ISpecialOffer[];
  title?: string;
  listType?: SpecialOfferListType;
  showTitle: boolean;
  showLabels: boolean;
}
