



























































import { defineComponent, PropType, ref, watch, onMounted } from 'vue';

import useValidationState from '@/validators/validation-state';
import bus from '@/bus';
import { useAppStore } from '@/store/appstore';
import { useCatalogStore } from '@/modules/catalog/dist/store';

import ListInputText from '@/components/fields/list-input-text.vue';
import ListInputTextarea from '@/components/fields/list-input-textarea.vue';

export default defineComponent({
  name: 'DeliveryMapAddressForm',
  components: { ListInputText, ListInputTextarea },
  props: {
    error: String as PropType<string>,
  },
  setup(props, { emit }) {
    const { validationState, onStateChanged, touchAll } = useValidationState();
    const appStore = useAppStore();

    const catalogStore = useCatalogStore();

    const form = ref(null);

    const room = ref<string>('');
    const domofon = ref<string>('');
    const entrance = ref<string>('');
    const floor = ref<string>('');
    const comment = ref<string>('');

    function updateForm() {
      room.value = catalogStore.state.deliveryAddressDetails?.room || '';
      domofon.value = catalogStore.state.deliveryAddressDetails?.domofon || '';
      entrance.value = catalogStore.state.deliveryAddressDetails?.entrance || '';
      floor.value = catalogStore.state.deliveryAddressDetails?.floor || '';
      comment.value = catalogStore.state.deliveryAddressDetails?.comment || '';
    }

    onMounted(() => {
      bus.$on('catalog:delivery:address:check', () => {
        room.value = '';
        domofon.value = '';
        entrance.value = '';
        floor.value = '';
        comment.value = '';
      });

      bus.$on('catalog:delivery:address:sheet:update', updateForm);
    });

    watch(() => appStore.state.appData, updateForm, {
      immediate: true,
    });

    const buttonClicked = ref<boolean>(false);

    function onConfirmClick() {
      buttonClicked.value = true;

      setTimeout(() => {
        if (validationState.invalid) {
          touchAll();

          const invalidInput = (form.value as unknown as HTMLElement)?.querySelector(
            '.item-input-invalid input',
          ) as HTMLInputElement;

          if (invalidInput) {
            invalidInput.focus();
          }

          return;
        }

        emit('confirm', {
          room: room.value,
          domofon: domofon.value,
          entrance: entrance.value,
          floor: floor.value,
          comment: comment.value,
        });
      }, 100);
    }

    function onClarifyClick() {
      bus.$emit('catalog:delivery:address:input-focus');
    }

    return {
      form,
      room,
      domofon,
      entrance,
      floor,
      comment,
      buttonClicked,
      onConfirmClick,
      onClarifyClick,
      validationState,
      onStateChanged,
    };
  },
});
