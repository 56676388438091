


































import { defineComponent } from 'vue';
import { f7 } from 'framework7-vue';

import config from '../news.conf.json';
import { appStoreMapper } from '@/store/appstore';
import { newsStoreMapper } from '../store';
import { showErrorDialog } from '@/misc';
import * as API from '@/api';

import NewsItem from './news-item.vue';
import NavbarSlotLeft from 'src/components/navbar-slot-left.vue';

export default defineComponent({
  name: 'News',
  components: {
    NavbarSlotLeft,
    NewsItem,
  },
  data: () => ({
    loading: false,
  }),
  created() {
    this.setNews([]);
  },
  computed: {
    asTab(): boolean {
      return this.settings?.tab ?? true;
    },
    ...appStoreMapper.mapState(['currentTab']),
    ...newsStoreMapper.mapGetters(['settings']),
    ...newsStoreMapper.mapState(['newsList', 'allNewsLoaded']),
  },
  methods: {
    onPTRRefresh(done: () => void) {
      done();

      if (this.settings.list) {
        this.loading = true;
        f7.preloader.show();

        API.getAppData()
          .then((response) => {
            this.setNews(response.settings.modules.news?.list || []);
          })
          .finally(() => {
            this.loading = false;
            f7.preloader.hide();
          });
      } else {
        this.loadNews();
      }
    },
    onInfinite() {
      if (!this.loading && !this.allNewsLoaded) {
        this.loadNews({ after: true });
      }
    },
    loadNews({ after }: { after?: boolean } = {}) {
      this.loading = true;
      if (!after) {
        f7.preloader.show();
      }

      this.getNews({ after, limit: this.settings.limit! })
        .catch((error) => {
          showErrorDialog.call(this, { error });
        })
        .finally(() => {
          this.loading = false;
          f7.preloader.hide();
        });
    },
    ...newsStoreMapper.mapActions(['getNews']),
    ...newsStoreMapper.mapMutations(['setNews']),
  },
  watch: {
    currentTab(value: string) {
      if (value === config.name && !this.newsList.length) {
        this.loadNews();
      }
    },
  },
});
