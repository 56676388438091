import { Module, createMapper, createComposable } from 'vuex-smart-module';

import NotificationsActions from './actions';
import NotificationsGetters from './getters';
import NotificationsMutations from './mutations';
import NotificationsState from './state';

const module = new Module({
  actions: NotificationsActions,
  getters: NotificationsGetters,
  mutations: NotificationsMutations,
  namespaced: true,
  state: NotificationsState,
});

export default module;

export const notificationsStoreMapper = createMapper(module);
export const useNotificationsStore = createComposable(module);
