


import { defineComponent, onMounted, watch } from 'vue';

import { IBranch } from '@/types';
import { AddressDetails, DeliveryAddress, ReceiveType } from '../types';

import { useAppStore } from '@/store/appstore';
import { useCatalogStore } from '../store';

import {
  LOCALSTORAGE_ADDRESS,
  LOCALSTORAGE_ADDRESS_BRANCH,
  LOCALSTORAGE_ADDRESS_DETAILS,
  LOCALSTORAGE_BASKET,
  LOCALSTORAGE_PICKUP,
  LOCALSTORAGE_RECEIVE,
} from '../const';

import Storage from '@/storage';
import { useObjections } from '@/composition';
import bus from '@/bus';

export default defineComponent({
  name: 'BasketDaemon',
  setup() {
    const appStore = useAppStore();
    const catalogStore = useCatalogStore();

    const { addObjection } = useObjections();

    onMounted(() => {
      addObjection('catalog:basket:add', () => {
        const objectionResult =
          (catalogStore.state.receive === ReceiveType.pickup &&
            !catalogStore.state.pickupPoint) ||
          (catalogStore.state.receive === ReceiveType.delivery &&
            !catalogStore.state.deliveryAddress);
        bus.$emit('objection', objectionResult);
        if (objectionResult) {
          bus.$emit('delivery:block');
        }
      });
    });

    function init() {
      let receive = Storage.getSync<Nullable<ReceiveType>>(LOCALSTORAGE_RECEIVE);
      const pickup = Storage.getSync<Nullable<IBranch>>(
        `${LOCALSTORAGE_PICKUP}-${appStore.state.domain}`,
      );
      const pickupBranch = appStore.getters.pickupPoints.find(
        (branch) => branch.idBranch === pickup?.idBranch,
      );
      const suggestion = Storage.getSync<Nullable<DeliveryAddress>>(
        `${LOCALSTORAGE_ADDRESS}-${appStore.state.domain}`,
      );
      const details = Storage.getSync<Nullable<AddressDetails>>(
        `${LOCALSTORAGE_ADDRESS_DETAILS}-${appStore.state.domain}`,
      );
      const branch = Storage.getSync<Nullable<number>>(
        `${LOCALSTORAGE_ADDRESS_BRANCH}-${appStore.state.domain}`,
      );

      if (suggestion || appStore.getters.pickupPoints.length === 0) {
        catalogStore.dispatch('setDeliveryAddress', { suggestion, details, branch });
      }

      if (pickupBranch || appStore.getters.pickupPoints.length === 1) {
        catalogStore.dispatch(
          'setPickupPoint',
          pickupBranch ||
            (appStore.getters.pickupPoints.length === 1
              ? appStore.getters.pickupPoints[0]
              : null),
        );
      }

      if (catalogStore.getters.receiveAvailable) {
        if (!receive) {
          receive = ReceiveType.delivery;
        }

        if (catalogStore.getters.singleReceiveTypeAvailable) {
          if (!catalogStore.getters.settings.pickup) receive = ReceiveType.delivery;
          if (!catalogStore.getters.settings.delivery) receive = ReceiveType.pickup;
        }

        catalogStore.commit('setReceive', receive);
      } else {
        catalogStore.commit('setReceive', null);
      }
    }

    catalogStore.commit('setBasket', Storage.getSync(LOCALSTORAGE_BASKET) || []);

    init();

    watch(() => appStore.state.appData, init);
    watch(
      () => appStore.state.domain,
      (value: string) => {
        const pickup = Storage.getSync<Nullable<IBranch>>(
          `${LOCALSTORAGE_PICKUP}-${value}`,
        );
        const suggestion = Storage.getSync<Nullable<DeliveryAddress>>(
          `${LOCALSTORAGE_ADDRESS}-${appStore.state.domain}`,
        );
        const details = Storage.getSync<Nullable<AddressDetails>>(
          `${LOCALSTORAGE_ADDRESS_DETAILS}-${appStore.state.domain}`,
        );
        const branch = Storage.getSync<Nullable<number>>(
          `${LOCALSTORAGE_ADDRESS_BRANCH}-${appStore.state.domain}`,
        );

        catalogStore.commit('setBasket', []);
        catalogStore.dispatch('setPickupPoint', pickup);
        catalogStore.dispatch('setDeliveryAddress', { suggestion, details, branch });
        catalogStore.commit('setCategories', []);
      },
    );

    return {};
  },
});
