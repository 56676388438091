


























































































import Vue, { PropType } from 'vue';

import { IBranch } from '@/types';
import bus from '@/bus';
import { appStoreMapper } from '@/store/appstore';
import { catalogStoreMapper } from '../store';
import getWorkingTimeFormatted from '@/utils/getWorkingTimeFormatted';

import BranchList from '@/components/branch-list.vue';
// import getBranchScheduleFormatted from '@/utils/getBranchScheduleFormatted';

let stepProgressRelaxTimeout: any = null;

export default Vue.extend({
  name: 'DeliveryMapSheetBranches',
  components: { BranchList },
  props: {
    stepOpened: Boolean as PropType<boolean>,
    map: Object as PropType<Nullable<any>>,
    markersList: Array as PropType<Array<[any, IMapPoint]>>,
  },
  data: () => ({
    onScrollTop: true,
    stepProgress: 0,
    opened: false,
  }),
  created() {
    bus.$on('catalog:delivery:branches:sheet-open', () => {
      (this.$refs.branches as any)?.f7Sheet.open();
    });

    bus.$on('catalog:delivery:branches:sheet-close', () => {
      (this.$refs.branches as any)?.f7Sheet.close();
    });

    bus.$on('catalog:delivery:branches:step-open', () => {
      (this.$refs.branches as any)?.f7Sheet.stepOpen();
    });

    bus.$on('catalog:delivery:branches:step-close', () => {
      (this.$refs.branches as any)?.f7Sheet.stepClose();
    });
  },
  mounted() {
    (this.$refs.wrapper as HTMLElement).addEventListener('scroll', () => {
      const scrollTop = (this.$refs.wrapper as HTMLElement).scrollTop;
      this.onScrollTop = !scrollTop;

      if (scrollTop < 0) {
        (this.$refs.wrapper as HTMLElement).scrollTop = 0;
      }
    });

    let startTouch: any = {};
    let currentTouch: any = {};
    let direction = '';

    (this.$refs.wrapper as HTMLElement).addEventListener('touchstart', (e: any) => {
      if (!this.stepOpened) {
        return false;
      }

      startTouch = {
        x: e.type === 'touchstart' ? e.targetTouches[0].pageX : e.pageX,
        y: e.type === 'touchstart' ? e.targetTouches[0].pageY : e.pageY,
      };

      if (this.stepOpened && !this.onScrollTop) {
        e.stopPropagation();
      }
    });

    (this.$refs.wrapper as HTMLElement).addEventListener('touchmove', (e: any) => {
      if (!this.stepOpened) {
        return false;
      }

      currentTouch = {
        x: e.type === 'touchmove' ? e.targetTouches[0].pageX : e.pageX,
        y: e.type === 'touchmove' ? e.targetTouches[0].pageY : e.pageY,
      };

      const touchesDiff = startTouch.y - currentTouch.y;

      direction = touchesDiff < 0 ? 'to-bottom' : 'to-top';

      if (
        this.stepOpened &&
        (!this.onScrollTop || (this.onScrollTop && direction === 'to-top'))
      ) {
        e.stopPropagation();
      }
    });
  },
  computed: {
    ...appStoreMapper.mapGetters(['pickupPoints', 'timeZoneOptions']),
    ...catalogStoreMapper.mapGetters(['singleReceiveTypeAvailable']),
    ...catalogStoreMapper.mapState(['deliveryAddress']),
  },
  methods: {
    onBranchClick(branch: IBranch) {
      const entry = this.markersList.find(
        (entry) => entry[1].id === (branch.idBranch as unknown as number),
      );
      if (entry) {
        this.map.panTo(entry[0].getPosition());
        this.setPickupPoint(branch);
        this.$emit('update:step-opened', false);
        this.$emit('branch:click', branch);
      }
    },
    onSheetStepOpen() {
      this.$emit('update:step-opened', true);
      this.opened = true;
    },
    onSheetStepClose() {
      this.$emit('update:step-opened', false);
      this.opened = false;
    },
    onSheetStepProgress(value: any, progress: any) {
      this.stepProgress = Math.round(progress * 100);

      stepProgressRelaxTimeout = setTimeout(() => {
        this.onSheetStepProgressRelax();
      }, 500);
    },
    onSheetStepProgressRelax() {
      clearTimeout(stepProgressRelaxTimeout);
      stepProgressRelaxTimeout = null;

      if (this.opened) {
        this.stepProgress = 1;
      } else {
        this.stepProgress = 0;
      }
    },
    workingTimeFormatted(schedule: WorkingSchedule, branch: IBranch): string {
      return getWorkingTimeFormatted(schedule, branch, this.timeZoneOptions, this.$i18n);
    },
    onScheduleClick(branch: IBranch) {
      //this.$f7.dialog.alert(getBranchScheduleFormatted(branch, this.$i18n), 'Время работы');
    },
    ...catalogStoreMapper.mapActions(['setPickupPoint']),
  },
});
