import { Context, Getters } from 'vuex-smart-module';
import { Store } from 'vuex';

import { IModuleSettings } from '../types';

import AppStore from '@/store/appstore';
import FeedbackState from './state';

export default class FeedbackGetters extends Getters<FeedbackState> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  get settings(): IModuleSettings {
    const settings = this.app.state.appData?.settings?.modules?.feedback || {};

    settings.shareAfterFeedbackMinStars = settings.shareAfterFeedbackMinStars || 5;

    return settings;
  }
}
