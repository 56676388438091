import { ISendBookingRequest, SendBookingResponse } from '../types';

import * as API from 'src/api';

const API_ENDPOINT_BOOKING = 'booking';

export async function sendBooking(
  data: ISendBookingRequest,
): Promise<SendBookingResponse> {
  return API.request<SendBookingResponse>({
    data,
    method: 'post',
    url: API_ENDPOINT_BOOKING,
  });
}
