import { IModule } from 'src/types';

import Vue from 'vue';
import { registerModule } from 'vuex-smart-module';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faUserFriends, faStars, faPercentage } from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import * as Misc from 'src/misc';
import config from './referral.conf.json';

import store from 'src/store';
import RefStore from './store';

import Referral from './components/referral.vue';
import ReferralHomeSlotButton from './components/referral-home-slot-button.vue';
import ReferralMenuItems from './components/referral-menu-items.vue';
import ReferralRegistrationField from './components/referral-registration-field.vue';
import ReferralSlot from './components/referral-slot.vue';

library.add(faTimes, faUserFriends, faStars, faPercentage);

export function init() {
  Vue.component('referral-home-slot-button', ReferralHomeSlotButton);
  Vue.component('referral-menu-items', ReferralMenuItems);
  Vue.component('referral-registration-field', ReferralRegistrationField);
  Vue.component('referral-slot', ReferralSlot);

  const routes = [
    {
      component: Referral,
      path: '/referral',
      name: 'Referral',
    },
  ];

  registerModule(store, ['ref'], 'ref/', RefStore);

  Misc.registerModuleConfig(config as IModule);
  Misc.registerModuleRoutes(routes);
}
