import { AppData, LogRocketSettings, SentrySettings } from '@/types';

import * as Sentry from '@sentry/vue';
import LogRocket from 'logrocket';

import config from 'src/app.config.js';
import bus from '@/bus';
import { LOCALSTORAGE_APPDATA, LOCALSTORAGE_RECORDSESSION } from '@/misc';
import Storage from '@/storage';
import store from 'src/store';

let logrocketInitialized = false;

function identifyUser() {
  const appData: AppData = store.state.appData;

  if (appData.user?.idUser) {
    LogRocket.identify(appData.user.idUser.toString(), {
      name: appData.user?.display_name || '',
      email: appData.user?.username || '',
    });
  }
}

export const initLogRocket = () => {
  const logrocketSettings: Nullable<LogRocketSettings> =
    window.storage[LOCALSTORAGE_APPDATA]?.settings?.modules?.logrocket;
  const sentrySettings: Nullable<SentrySettings> =
    window.storage[LOCALSTORAGE_APPDATA]?.settings?.modules?.sentry;
  const recordSessionState = Storage.getSync(LOCALSTORAGE_RECORDSESSION);

  if (recordSessionState || logrocketSettings?.enabled) {
    const project = config.plugins.logrocketProject || config.plugins.sentryProject;

    if (config.plugins.logrocketId && project) {
      LogRocket.init(`${config.plugins.logrocketId}/${project}`);

      logrocketInitialized = true;

      if (!sentrySettings || sentrySettings.enabled) {
        LogRocket.getSessionURL((sessionURL) => {
          Sentry.configureScope((scope: any) => {
            scope.setExtra('sessionURL', sessionURL);
          });
        });
      }

      bus.$on('appDataDidLoad', identifyUser);
    }
  }

  bus.$on('recordSession', (state: number) => {
    if (state === 1 && !logrocketInitialized) {
      initLogRocket();
      identifyUser();
    }
  });
};
