import { Store } from 'vuex';
import { Context } from 'vuex-smart-module';
import { Dialog } from 'framework7/components/dialog/dialog';
import { f7 } from 'framework7-vue';

import { IModule } from 'src/types';
import { IActionButton } from '@/modules/notifications/dist/types/onesignal';
import { IModuleSettings as IModuleSettingsApp } from '@/store/types';

import bus from 'src/bus';
import i18n from 'src/translate/lang';
import store from 'src/store';
import config from 'src/app.config.js';
import { request } from '@/api';
import { API_ENDPOINT_STAT } from '@/misc/const';
import { formatDate, setFormatDateLocale } from '@/utils/formatDate';

import Settings from '@/components/Settings.vue';
import AppStore from 'src/store/appstore';

export * from 'src/utils/formatNumber';
export * from 'src/utils/normalizeString';
export * from 'src/utils/price2Mils';
export * from 'src/utils/priceAfterDelimiter';
export * from 'src/misc/const';

export interface ISendStatRequest {
  action: string;
  id: Nullable<number | string>;
  date?: string;
}

export function errorHandler(error: Error | string): void {
  const text = typeof error !== 'string' ? error.message || error.name : error;

  showMessageDialog({
    title: i18n.t('global.errorTitle').toString(),
    text,
  });
}

export function hideSplash(): void {
  if (config.app.miniapp) {
    hideSplashBody();
  } else {
    document.addEventListener('deviceready', () => {
      setTimeout(hideSplashBody, store.getters.appSettings.splashHideTimeout ?? 100);
    });
  }
}

function hideSplashBody() {
  if (store.state.splashVisible) {
    bus.$emit('splashHide');
    store.commit('setSplashVisibility', false);

    const splash = document.getElementById('pwa-splash');
    if (splash) {
      setTimeout(() => {
        splash.style.display = 'none';
      }, 500);
    }

    // Необходимо вызывать только после скрытия сплеша, причем только с таймаутом
    if (window.cordova.platformId !== 'ios') {
      let count = 5;

      setTimeout(() => {
        navigator?.splashscreen?.hide();

        const interval = setInterval(() => {
          window.StatusBar.backgroundColorByHexString(config.theme.iosBackgroundColor);

          if (config.theme.iosTextColor === 'white') {
            window.StatusBar.styleDefault();
          } else {
            window.StatusBar.styleLightContent();
          }

          const color =
            config.theme.navigationBackgroundColor || config.theme.iosBackgroundColor;
          const light = config.theme.navigationTextColor || config.theme.iosTextColor;

          window.NavigationBar.backgroundColorByHexString(color, light === 'black');

          count--;

          if (count < 0) {
            clearInterval(interval);
          }
        }, 200);
      }, 400);
    } else {
      navigator?.splashscreen?.hide();
    }
  }
}

export interface IShowMessageDialog {
  title?: string;
  text?: string;
  buttons?: IActionButton[];
}

export function showMessageDialog(params: IShowMessageDialog): Promise<Dialog.Dialog> {
  if (!params.buttons) {
    params.buttons = [];
  }

  params.buttons.push({
    id: undefined,
    text: i18n.t('global.dialog.close').toString(),
  });

  params.buttons.forEach((button) => {
    if (button.id) {
      // @ts-ignore
      button.onClick = (dialog: any, e: InputEvent) => {
        bus.$emit(button.id!);
      };
    }
  });

  return new Promise((resolve, reject) => {
    const dialog = f7.dialog.create({
      buttons: params.buttons,
      text: params.text || undefined,
      title: params.title || undefined,
    });

    dialog && dialog.open();

    if (dialog) {
      resolve(dialog);
    } else {
      reject();
    }
  });
}

export interface IShowErrorDialog {
  buttons?: any[];
  callbacks?: {
    onClose(dialog?: Dialog.Dialog, event?: MouseEvent): void;
  };
  closeButtonText?: string;
  error: any;
}

export function showErrorDialog(params: IShowErrorDialog): Dialog.Dialog {
  let text: string = i18n.t('global.errorServer').toString();

  if (
    params.error.status &&
    params.error.status === 'error' &&
    (params.error.message || params.error.error)
  ) {
    text = params.error.message || params.error.error;
  } else if (params.error.message || params.error.error || params.error.name) {
    text = params.error.message || params.error.error || params.error.name;
  }

  if (text === 'Network Error') {
    text = i18n.t('global.errorNetwork').toString();
  }

  let dialogButtons: any[] = [];

  dialogButtons = dialogButtons.concat(params.buttons || [], [
    {
      text: params.closeButtonText || i18n.t('global.dialog.close').toString(),
      onClick(dialog: any, event: any) {
        if (
          typeof params.callbacks !== 'undefined' &&
          typeof params.callbacks.onClose === 'function'
        ) {
          params.callbacks.onClose(dialog, event);
        }
      },
    },
  ]);

  const dialog = f7.dialog.create({
    buttons: dialogButtons,
    text: text,
    title: i18n.t('global.errorTitle') as string,
  });

  dialog && dialog.open();

  return dialog;
}

export function registerModuleConfig(config: IModule) {
  if (!window.modules) {
    window.modules = [];
  }

  window.modules.push(config);
}

export function registerModuleRoutes(routes: any[]) {
  if (!window.routes) {
    window.routes = [
      {
        component: Settings,
        path: '/settings/',
      },
    ];
  }

  window.routes = window.routes.concat(routes);
}

export function getModule(name: string): IModule {
  return window.modules.find((module) => module.name === name) || (null as any);
}

export function goToModule({ name }: { name: string }) {
  if (store.state.appData.settings?.modules?.[name] != null) {
    const m = getModule(name);

    if (m.tabName) {
      f7.tab.show(`#${m.tabName}`);
    } else if (m.url) {
      f7.views.current.router.navigate(m.url);
    }
  }
}

export function openUrl(url: string, target?: string, e?: Event, options?: string) {
  if ((!config.app?.miniapp && !config.app?.pwa) || options) {
    e?.preventDefault();

    if (window.devMode) {
      console.log(url, target, options);
    }

    return window.cordova.InAppBrowser.open(url, target, options);
  } else {
    window.open(url, target);
  }
}

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function rgbToHex(
  r: number | string,
  g: number | string,
  b: number | string,
): string {
  r = Number(r);
  g = Number(g);
  b = Number(b);

  if (!Number.isNaN(r) && !Number.isNaN(g) && !Number.isNaN(b)) {
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }

  return 'Invalid parameters';
}

export function checkModuleIsActive(slot: string, store: Store<any>): boolean {
  const activeModules = store.getters.activeModules;
  const moduleName = slot.split('-')[0];

  if (!moduleName) {
    return false;
  }

  return activeModules.includes(moduleName);
}

export async function sendStat(
  data: ISendStatRequest,
  appStore: Context<typeof AppStore>,
) {
  const settings = store.getters['appSettings'] as IModuleSettingsApp;

  if (!settings.statDisabled) {
    data.date = formatDate(new Date(), { type: 'ISO' });
    store.commit('pushStatQueue', data);

    sendStatQueue(appStore);
  }
}

export function sendStatQueue(appStore: Context<typeof AppStore>) {
  if (
    !navigator.onLine ||
    appStore.getters.appSettings.statDisabled ||
    appStore.state.statInQueue
  ) {
    return;
  }

  if (appStore.state.statQueue.length) {
    const data = appStore.state.statQueue[0];

    store.commit('setStatInQueue', data);

    request({
      method: 'post',
      url: API_ENDPOINT_STAT,
      data,
    })
      .then(() => {
        const itemIndex = appStore.state.statQueue.findIndex(
          (item) => item.date === data.date,
        );

        if (itemIndex >= 0) {
          store.commit('spliceStatQueue', itemIndex);
        }

        if (window.devMode) {
          console.log('sendStat', data);
        }
      })
      .finally(() => {
        store.commit('setStatInQueue', null);

        if (appStore.state.statQueue.length && navigator.onLine) {
          setTimeout(() => {
            sendStatQueue(appStore);
          }, 10 * 1000);
        }
      });
  }
}

export async function getCurrentPosition({ silent }: { silent?: boolean } = {}): Promise<
  Nullable<Coords>
> {
  const getPositionOptions = {
    enableHighAccuracy: true,
    maximumAge: 60 * 60 * 1000,
    timeout: 30 * 1000,
  };

  function getCurrentPosition(
    resolve: (value: Nullable<Coords>) => void,
    reject: (reason?: any) => void,
  ) {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }: any) => {
        resolve({
          lat,
          lng,
        });
      },
      (error: any) => {
        if (error.code === 1) {
          reject(error);
        } else {
          resolve({
            lat: 0,
            lng: 0,
          });
        }
      },
      getPositionOptions,
    );
  }

  return new Promise((resolve, reject) => {
    if (window.cordova.plugins?.diagnostic) {
      window.cordova.plugins?.diagnostic?.getLocationAuthorizationStatus(
        (status: CordovaLocationPermissionState) => {
          if (
            !silent ||
            status === 'GRANTED' ||
            status === 'GRANTED_WHEN_IN_USE' ||
            status === 'authorized_when_in_use'
          ) {
            getCurrentPosition(resolve, reject);
            return;
          }

          resolve(null);
        },
      );
    } else {
      getCurrentPosition(resolve, reject);
    }
  });
}

export function getDistanceBetweenTwoPoints(cord1: Coords, cord2: Coords) {
  if (cord1.lat == cord2.lat && cord1.lng == cord2.lng) {
    return 0;
  }

  const radlat1 = (Math.PI * cord1.lat) / 180;
  const radlat2 = (Math.PI * cord2.lat) / 180;

  const theta = cord1.lng - cord2.lng;
  const radtheta = (Math.PI * theta) / 180;

  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

  if (dist > 1) {
    dist = 1;
  }

  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  dist = dist * 1.609344; //convert miles to km
  dist = dist * 1000; //convert km to meters

  return dist;
}

/*!
 * notch-detected-event.js - v@version@
 * A cross-browser script to detect the existence of a device notch/cutout
 * https://github.com/john-doherty/notch-detected-event
 * @inspiration https://stackoverflow.com/questions/46318395/detecting-mobile-device-notch
 * @author John Doherty <www.johndoherty.info>
 * @license MIT
 */
export function detectNotch() {
  window.addEventListener('load', function () {
    // we need to wait before executing to allow layout to happen
    setTimeout(function () {
      // get the <html> element
      const root = document.documentElement;

      // add CSS variables so we can read them back
      root.style.setProperty('--notch-top', 'env(safe-area-inset-top)');
      root.style.setProperty('--notch-right', 'env(safe-area-inset-right)');
      root.style.setProperty('--notch-bottom', 'env(safe-area-inset-bottom)');
      root.style.setProperty('--notch-left', 'env(safe-area-inset-left)');

      // get runtime styles
      const computedStyle = window.getComputedStyle(root);

      // read env values back and check if we have any values
      const hasNotch =
        [
          computedStyle.getPropertyValue('--notch-top') || '-1',
          computedStyle.getPropertyValue('--notch-right') || '-1',
          computedStyle.getPropertyValue('--notch-bottom') || '-1',
          computedStyle.getPropertyValue('--notch-left') || '-1',
        ]
          .map(parseInt)
          .filter(function (val) {
            return val > 0;
          }).length > 0;

      // only if we have a notch
      if (hasNotch) {
        // set <html data-notch="true"> to allow CSS to tweak the display
        root.setAttribute('data-notch', 'true');

        // fire global notch-detected event to let the UI know that happened
        window.dispatchEvent(
          new CustomEvent('notch-detected', { bubbles: true, cancelable: true }),
        );
      }

      // remove CSS variables
      root.style.removeProperty('--notch-top');
      root.style.removeProperty('--notch-right');
      root.style.removeProperty('--notch-bottom');
      root.style.removeProperty('--notch-left');
    }, 250);
  });

  // patch CustomEvent to allow constructor creation (IE/Chrome)
  if (typeof window.CustomEvent !== 'function') {
    window.CustomEvent = function (event: any, params: any) {
      params = params || { bubbles: false, cancelable: false, detail: undefined };

      const evt = document.createEvent('CustomEvent');
      evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
      return evt;
    };

    window.CustomEvent.prototype = window.Event.prototype;
  }
}

export function setLocale(value: string): string {
  const locale = getLanguage(value);
  i18n.locale = locale;
  setFormatDateLocale(locale);
  return locale;
}

export function getLanguage(lang?: string): string {
  const availableLanguages = config.lang.list;
  const defaultLanguage = config.lang.default;
  const userLanguage =
    lang ||
    (navigator.languages &&
      navigator.languages.length &&
      navigator.languages[0].split('-')[0]) ||
    navigator.language.split('-')[0];

  let resultLanguage: string;

  if (userLanguage && ~availableLanguages.indexOf(userLanguage)) {
    resultLanguage = userLanguage;
  } else {
    resultLanguage = availableLanguages[availableLanguages.indexOf(defaultLanguage)];
  }

  return resultLanguage;
}
