import { formatDate } from '@/utils';

export const getFormattedDate = (date: Date | string): Date | string => {
  const newDate = new Date(date);

  if (!date || (typeof date !== 'string' && Number.isNaN(newDate.getTime()))) {
    return date;
  } else if (typeof date === 'string' && date.length === 10) {
    const formattedDate = date.split('.').reverse().join('-');

    return /^\d{4}-\d{2}-\d{2}$/.test(formattedDate) ? formattedDate : date;
  }

  return formatDate(newDate, { formatString: 'yyyy-MM-dd' });
};
