





























import { defineComponent, PropType } from 'vue';

import { CouponWithType, GiftWithType } from '../types';

import { appStoreMapper } from '@/store/appstore';
import { giftsStoreMapper } from '../store';
import getImageThumbnailPath from '@/utils/getImageThumbnailPath';

import CatalogItem from 'src/components/CatalogItem.vue';
import GiftPopup from './gift-popup.vue';
import CouponPopup from './coupon-popup.vue';

export default defineComponent({
  name: 'GiftsListEntry',
  components: { CatalogItem, GiftPopup, CouponPopup },
  props: {
    gift: {
      type: Object as PropType<GiftWithType | CouponWithType>,
      required: true,
    },
  },
  data: () => ({
    popupOpened: false,
  }),
  computed: {
    item(): GiftWithType | CouponWithType {
      return this.gift;
    },
    labelComputed(): string {
      return (
        (this.item.price
          ? this.item.label
          : this.item.label || this.$t('modules.gifts.actions.now').toString()) || ''
      );
    },
    ...appStoreMapper.mapGetters(['fullImagePath', 'deviceWidth']),
    ...giftsStoreMapper.mapGetters(['settings']),
  },
  methods: {
    showPopup() {
      this.popupOpened = true;
    },
    getGiftImage(photoId: Nullable<number>): string {
      if (!photoId) return '';

      return this.fullImagePath(
        getImageThumbnailPath(photoId, { deviceWidth: this.deviceWidth }),
      );
    },
  },
});
