import { Context, Getters } from 'vuex-smart-module';
import { Store } from 'vuex';

import { IModuleSettings } from '../types';

import AppStore from '@/store/appstore';
import ActionsState from './state';

export default class ActionsGetters extends Getters<ActionsState> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  get settings(): IModuleSettings {
    const settings = this.app.state.appData.settings?.modules?.actions || {};

    settings.cardShowDate = settings.cardShowDate ?? true;
    settings.cardShowTitle = settings.cardShowTitle ?? true;
    settings.cardCutTitle = settings.cardCutTitle ?? false;
    settings.cardTitleOverImage = settings.cardTitleOverImage ?? false;
    settings.limit = settings.limit ?? 10;

    return settings;
  }
}
