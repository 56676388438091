


















































import Vue, { PropType } from 'vue';
import { ContentLoader } from 'vue-content-loader';

import { Coupon } from '../types';

import { giftsStoreMapper } from '../store';
import { appStoreMapper } from '@/store/appstore';
import { getCoupon } from '../api';
import getImageThumbnailPath from '@/utils/getImageThumbnailPath';
import { useBrightness } from '@/composition';
import config from '@/app.config.js';
import bus from '@/bus';
import { sendStat } from '@/misc';

import ContentBlock from '@/components/content.vue';
import ThePopup from '@/components/ThePopup.vue';
import TheBarWrapper from '@/components/TheBarWrapper.vue';
import GiftButtonAdd from './gift-button-add.vue';
import GiftCodeContent from './gift-code-content.vue';

let brightnessTurnOn: () => void;
let brightnessTurnOff: () => void;

export default Vue.extend({
  name: 'CouponPopup',
  inheritAttrs: false,
  components: {
    ContentBlock,
    ThePopup,
    TheBarWrapper,
    GiftButtonAdd,
    ContentLoader,
    GiftCodeContent,
  },
  props: {
    item: Object as PropType<Nullable<Coupon>>,
    id: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  setup() {
    const { brightnessTurnOn: turnOn, brightnessTurnOff: turnOff } = useBrightness();

    brightnessTurnOn = turnOn;
    brightnessTurnOff = turnOff;

    return {
      config,
    };
  },
  data: () => ({
    coupon: null as Nullable<Coupon>,
    showCode: false,
    brightnessHigh: false,
    loading: false,
    error: false,
  }),
  mounted() {
    if (this.item) {
      this.coupon = this.item;
    } else {
      this.getCoupon();
    }

    sendStat({ action: 'show-coupon', id: this.id }, this.$store as any);
  },
  computed: {
    images(): string[] {
      return this.coupon?.icon && !this.showCode
        ? [
            this.fullImagePath(
              getImageThumbnailPath(this.coupon.icon, 'scale', {
                width: this.deviceWidth,
                height: 0,
              }),
            ),
          ]
        : [];
    },
    ...appStoreMapper.mapGetters(['decimalOptions', 'fullImagePath', 'deviceWidth']),
    ...giftsStoreMapper.mapState(['coupons']),
  },
  methods: {
    async getCoupon() {
      this.error = false;
      this.coupon = this.getCouponById(this.id);

      if (!this.coupon) {
        this.loading = true;

        try {
          this.coupon = await getCoupon(this.id);

          if (!this.coupon) {
            this.error = true;
          }
        } catch (e) {
          this.error = true;
        } finally {
          this.loading = false;
        }
      }
    },
    onReload() {
      this.getCoupon();
    },
    getCouponById(id: number): Nullable<Coupon> {
      return this.coupons.find((coupon) => coupon.id === id);
    },
    onConfirm() {
      this.showCode = true;

      this.brightnessHigh = true;
      brightnessTurnOn();

      bus.$emit('bonus:spent:add', this.coupon?.price || 0);
    },
    onDone() {
      this.$f7.popup.close('.popup-universal');
    },
    onPopupClose() {
      if (this.brightnessHigh) {
        this.brightnessHigh = false;

        brightnessTurnOff();
      }
    },
  },
});
