import { createMapper, Module } from 'vuex-smart-module';

import SpecialOfferActions from './actions';
import SpecialOfferGetters from './getters';
import SpecialOfferMutations from './mutations';
import SpecialOfferState from './state';

const module = new Module({
  actions: SpecialOfferActions,
  getters: SpecialOfferGetters,
  mutations: SpecialOfferMutations,
  namespaced: true,
  state: SpecialOfferState,
});

export default module;

export const specialOfferStoreMapper = createMapper(module);
