







































































































































import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue';
import { f7ready } from 'framework7-vue';

import getLocalizedPrice from '@/utils/getLocalizedPrice';
import { formatNumber } from '@/utils/formatNumber';
import { useObjections } from '@/composition';
import { useAppStore } from '@/store/appstore';
import i18n from '@/translate/lang';

import BonusBadge from '@/components/bonus-badge.vue';
import BonusIcon from '@/components/bonus-icon.vue';
import ItemBadges from '@/components/item-badges.vue';

export default defineComponent({
  name: 'CatalogItem',
  components: { ItemBadges, BonusBadge, BonusIcon },
  props: {
    type: { type: String as PropType<'gift' | 'product' | 'branch'>, default: 'gift' },
    image: { type: String as PropType<string>, default: '' },
    name: { type: String as PropType<string>, required: true },
    bonus: { type: Number as PropType<number>, validator: (value: number) => value >= 0 },
    price: { type: Number as PropType<number>, validator: (value: number) => value >= 0 },
    label: { type: String as PropType<string> },
    amount: {
      type: Number as PropType<number>,
      default: 0,
      validator: (value: number) => !(value % 1) && value >= 0,
    },
    maxAmount: {
      type: Number as PropType<Nullable<number>>,
      validator: (value: Nullable<number>) => value == null || value >= 0,
    },
    options: {
      type: Object as PropType<IDecimalOptions>,
      default: () => ({
        decimalRoundingRule: 'floor',
      }),
      validator: (options: IDecimalOptions) => {
        return (
          options.decimalRoundingRule === 'floor' ||
          options.decimalRoundingRule === 'ceil' ||
          options.decimalRoundingRule === 'default' ||
          (!!options.decimalRoundingRule && options.decimal != null)
        );
      },
    },
    titleHideAfterImageLoaded: { type: Boolean as PropType<boolean> },
    titleHide: { type: Boolean as PropType<boolean> },
    showButton: { type: Boolean as PropType<boolean>, default: true },
    showAmountControl: { type: Boolean as PropType<boolean>, default: true },
    badges: Array as PropType<ItemBadge[]>,
  },
  setup(props, { emit }) {
    const appStore = useAppStore();

    const { checkObjection } = useObjections();

    const lazyRef = ref<any>(null);
    const loadError = ref<boolean>(false);
    const showFooter = ref<boolean>(true);
    const amountInner = ref<number>(props.amount);
    const prevAmount = ref<number | string>(props.amount ? props.amount : '');
    const nextAmount = ref<number | string>(props.amount ? props.amount : '');
    const showAmountAnimationToggle = ref<boolean>(true);

    const showButtons = computed<boolean>(
      () =>
        (props.showButton && appStore.getters.appSettings.catalogItemShowButtons) ?? true,
    );
    const buttonVisibility = computed<boolean>(
      () => showButtons.value && (!!props.label || !!props.bonus || props.price != null),
    );
    const isEmpty = computed<boolean>(() => amountInner.value === 0);
    const isPlusDisabled = computed<boolean>(
      () => !!props.maxAmount && amountInner.value >= props.maxAmount,
    );
    const currency = computed<ICurrencyI18n>(() => appStore.getters.appSettings.currency);
    const priceFormatted = computed<string>(() => {
      if (props.price && props.maxAmount === 0) {
        return i18n.t('modules.catalog.basket.list.sold').toString();
      }

      return props.price != null
        ? getLocalizedPrice(
            props.price,
            currency.value,
            props.options.decimalRoundingRule,
            props.options.decimal,
          )
        : '';
    });
    const bonusFormatted = computed<string>(() =>
      props.bonus
        ? formatNumber(
            props.bonus,
            props.options.decimalRoundingRule,
            props.options.decimal,
          )
        : '',
    );
    const imageAlreadyLoaded = computed<boolean>(() =>
      appStore.state.lazyLoadedImagesUrls.includes(props.image),
    );
    const cropTitle = computed<boolean>(
      () => appStore.getters.appSettings.catalogItemCropTitle ?? true,
    );
    const showLabels = computed<boolean>(
      () => appStore.getters.appSettings.catalogItemShowLabels ?? false,
    );
    const animationDirection = computed<string>(() => {
      if (showAmountAnimationToggle.value) {
        return Number(nextAmount.value) - Number(prevAmount.value) >= 0
          ? 'to-bottom'
          : 'to-top';
      } else {
        return Number(prevAmount.value) - Number(nextAmount.value) >= 0
          ? 'to-bottom'
          : 'to-top';
      }
    });
    const plusPause = ref<boolean>(false);
    const doShake = ref<boolean>(false);
    const isProduct = computed<boolean>(() => props.type === 'product');

    const showAmountControlComputed = computed(() => {
      return props.maxAmount != null
        ? props.maxAmount > 0 && props.showAmountControl
        : props.showAmountControl;
    });
    const soldOut = computed(() => isProduct.value && props.maxAmount === 0);

    watch(
      () => props.amount,
      () => {
        amountInner.value = props.amount;
      },
    );
    watch(() => amountInner.value, emit.bind(this, 'update:amount'));

    onMounted(() => {
      watch(
        () => amountInner.value,
        (next: number, prev: number) => {
          const _prevAmount = showAmountAnimationToggle.value ? prev : next;
          const _nextAmount = showAmountAnimationToggle.value ? next : prev;

          prevAmount.value = _prevAmount ? _prevAmount : '';
          nextAmount.value = _nextAmount ? _nextAmount : '';

          showAmountAnimationToggle.value = !showAmountAnimationToggle.value;
        },
      );

      if (props.titleHideAfterImageLoaded) {
        f7ready((f7) => {
          f7.$(lazyRef.value).on('lazy:loaded', () => {
            showFooter.value = false;
          });
        });
      }

      f7ready((f7) => {
        f7.$(lazyRef.value).on('lazy:loaded', function (this: HTMLElement) {
          setTimeout(() => {
            this.classList.remove('lazy-loaded', 'lazy-fade-in');
            props.image && appStore.commit('setLazyLoadedImagesUrls', props.image);
          }, 1000);
        });
      });

      f7ready((f7) => {
        f7.$(lazyRef.value).on('lazy:error', () => {
          loadError.value = true;
        });
      });
    });

    function onClick() {
      emit('click');
    }

    function onMinusClick() {
      if (amountInner.value === 1) {
        plusPause.value = true;
        setTimeout(() => {
          plusPause.value = false;
        }, 500);
      }

      if (amountInner.value <= 0) return;

      amountInner.value--;
    }

    function onPlusClick() {
      checkObjection('catalog:basket:add').then((result) => {
        if (!result) {
          if (doShake.value) return;

          if (!!props.maxAmount && amountInner.value >= props.maxAmount) {
            doShake.value = true;
            setTimeout(() => {
              doShake.value = false;
            }, 500);

            return;
          }

          amountInner.value++;
        }
      });
    }

    return {
      appSettings: appStore.getters.appSettings,
      lazyRef,
      showLabels,
      cropTitle,
      showFooter,
      loadError,
      buttonVisibility,
      priceFormatted,
      bonusFormatted,
      imageAlreadyLoaded,
      animationDirection,
      amountInner,
      isEmpty,
      isPlusDisabled,
      prevAmount,
      nextAmount,
      showAmountAnimationToggle,
      plusPause,
      doShake,
      isProduct,
      onClick,
      onMinusClick,
      onPlusClick,
      showAmountControlComputed,
      soldOut,
    };
  },
});
