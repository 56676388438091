










import Vue from 'vue';

import { OSNotificationOpenedResult } from '@/modules/notifications/dist/types/onesignal';
import { GiftType } from '@/modules/gifts/dist/types';

import bus from 'src/bus';

import GiftPopup from './gift-popup.vue';

export default Vue.extend({
  components: {
    GiftPopup,
  },
  data: () => ({
    id: null as Nullable<number>,
    type: null as Nullable<GiftType>,
    popupOpened: false,
  }),
  destroyed() {
    bus.$off('gift-push', this.handlePush);
  },
  mounted() {
    bus.$on('gift-push', this.handlePush);
  },
  methods: {
    async handlePush(data: OSNotificationOpenedResult) {
      const [type, id] = (
        data.notification.additionalData?.idConnectedEntity as unknown as string
      ).split('-') as [GiftType, string];
      this.id = Number(id);
      this.type = type;
      this.popupOpened = !!this.id;
    },
  },
});
