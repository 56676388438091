import { createComposable, createMapper, Module } from 'vuex-smart-module';

import FeedbackState from './state';
import FeedbackMutations from './mutations';
import FeedbackGetters from './getters';
import FeedbackActions from './actions';

const module = new Module({
  actions: FeedbackActions,
  getters: FeedbackGetters,
  mutations: FeedbackMutations,
  namespaced: true,
  state: FeedbackState,
});

export default module;

export const feedbackStoreMapper = createMapper(module);
export const useFeedbackStore = createComposable(module);
