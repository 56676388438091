
import { defineComponent, h, useCssModule } from 'vue';
import { f7 } from 'framework7-vue';

import config from '../maps.conf.json';
import { mapsStoreMapper } from '../store';
import i18n from '@/translate/lang';

export default defineComponent({
  name: 'MapsMenuItem',
  render() {
    const style = useCssModule();

    return h(
      'f7-list-item',
      {
        slot: 'list',
        props: {
          title: this.settings?.title || i18n.t(`modules.${config.name}.title`),
        },
        on: {
          click: () => {
            f7.panel.close();
            f7.views.current.router.navigate(config.url);
          },
        },
      },
      [
        h('div', {
          class: style.icon,
          slot: 'media',
        }),
      ],
    );
  },
  computed: {
    ...mapsStoreMapper.mapGetters(['settings']),
  },
});
