import { IModule } from 'src/types';

import Vue from 'vue';
import { registerModule } from 'vuex-smart-module';
import { faMapMarkerAlt, faPhoneAlt } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import {
  faMapSigns,
  faMapMarked,
  faLocationArrow,
  faCircleNotch,
} from '@fortawesome/pro-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import * as Misc from 'src/misc';
import config from './maps.conf.json';

import store from 'src/store';
import MapsStore from './store';

import Maps from './components/maps.vue';
import MapsMenuItem from './components/maps-menu-item.vue';
import MapsTabbarItem from './components/maps-tabbar-item.vue';

library.add(
  faTimes,
  faMapSigns,
  faMapMarked,
  faMapMarkerAlt,
  faLocationArrow,
  faCircleNotch,
  faPhoneAlt,
);

export function init() {
  Vue.component('maps-menu-item', MapsMenuItem);
  Vue.component('maps-tabbar-item', MapsTabbarItem);

  const routes = [
    {
      component: Maps,
      path: config.url,
    },
  ];

  registerModule(store, ['maps'], 'maps/', MapsStore);

  Misc.registerModuleConfig(config as IModule);
  Misc.registerModuleRoutes(routes);
}
