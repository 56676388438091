import { f7 } from 'framework7-vue';

import { Gift } from './types';

import { useGiftsStore } from './store';

export interface IGiftEntryProps {
  id: number;
  gift: Gift;
}

export const mixin = (props: IGiftEntryProps) => {
  const giftsStore = useGiftsStore();

  let gift: Nullable<Gift> = null;

  if (props.gift) {
    gift = props.gift;
  } else if (props.id) {
    gift = giftsStore.state.gifts.find((gift: Gift) => gift.id === props.id);
  }

  function goToGift() {
    if (gift) {
      f7.views.current.router.navigate('/gifts/body/', {
        props: { id: gift.id },
      });
    }
  }

  return { goToGift };
};
