

































import Vue from 'vue';
import { satisfies } from 'compare-versions';
import { faAppStore, faGooglePlay } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import config from '@/../package.json';
import { appStoreMapper } from '@/store/appstore';
import bus from '@/bus';
import Storage from '@/storage';
import { LOCALSTORAGE_NEED_UPDATE_TIMESTAMP } from '@/misc';
import Store from '@/storage';

library.add(faAppStore, faGooglePlay);

export default Vue.extend({
  name: 'UpdateScreen',
  mounted() {
    bus.$on('appDataDidLoadFromServer', () => {
      this.$f7ready(() => {
        const now = +new Date();
        const needUpdateLastAlertTimestamp =
          parseInt(Store.getSync(LOCALSTORAGE_NEED_UPDATE_TIMESTAMP), 10) || 0;
        const showDialog =
          this.appSettings.versionUpdateRequired ||
          (needUpdateLastAlertTimestamp
            ? now - needUpdateLastAlertTimestamp >= 60 * 60 * 24 * 1000
            : true);

        // TODO: dont hide is need age check
        if (this.needUpdate && showDialog) {
          this.$f7.loginScreen.open('.welcome-screen');

          Storage.setSync(LOCALSTORAGE_NEED_UPDATE_TIMESTAMP, now);
        }
      });
    });
  },
  computed: {
    needUpdate(): boolean {
      const os = this.$theme.ios ? 'ios' : 'android';
      const serverVersion: Nullable<string> = this.appSettings.version?.[os];
      const buildVersion: string = config.version;

      return !!serverVersion && !satisfies(buildVersion, serverVersion);
    },
    ...appStoreMapper.mapGetters(['appSettings', 'extraLogo']),
  },
  methods: {
    onHomeClick() {
      this.$f7.loginScreen.close('.welcome-screen');
    },
    onUpdateClick() {
      const link = this.$theme.ios
        ? this.appSettings.versionUpdateLinkAppStore
        : this.appSettings.versionUpdateLinkGogglePlay;

      if (link) {
        window.open(link, '_system');
      }
    },
  },
});
