
import { defineComponent } from 'vue';
import { CreateElement } from 'vue';

import config from '../actions.conf.json';
import bus from 'src/bus';
import { actionsStoreMapper } from '../store';
import { appStoreMapper } from 'src/store/appstore';

export default defineComponent({
  name: 'ActionsTabbarItem',
  render(this: any, h: CreateElement) {
    return h('f7-link', {
      class: [
        this.$style.link,
        {
          ['tab-link-' + config.tabName]: true,
        },
      ],
      props: {
        key: config.name,
        href: `#${config.tabName}`,
        text: this.settings?.tabbarTitle || this.$t(`modules.${config.name}.tabbarTitle`),
        'tab-link': true,
        'tab-link-active': this.defaultTab
          ? config.name === this.defaultTab
          : config.name === 'home',
      },
      on: {
        click: () => {
          bus.$emit('tabWillOpen', config.name);
        },
      },
    });
  },
  computed: {
    ...actionsStoreMapper.mapGetters(['settings']),
    ...appStoreMapper.mapGetters(['defaultTab']),
  },
});
