import { Context, Getters } from 'vuex-smart-module';
import { Store } from 'vuex';

import { IModuleSettings } from '../types';

import AppStore from '@/store/appstore';
import HistoryState from './state';

export default class HistoryGetters extends Getters<HistoryState> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  get settings(): IModuleSettings {
    const settings: IModuleSettings =
      this.app.state.appData?.settings?.modules?.history || {};

    return settings;
  }
}
