import { Store } from 'vuex';
import { Actions, Context } from 'vuex-smart-module';

import { OSNotification, OSNotificationOpenedResult, Tags } from '../types/onesignal';

import * as OS from '../utils';

import AppStore from '@/store/appstore';
import NotificationsState from './state';
import NotificationsGetters from './getters';
import NotificationsMutations from './mutations';

export default class NotificationsActions extends Actions<
  NotificationsState,
  NotificationsGetters,
  NotificationsMutations,
  NotificationsActions
> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  setTag({ key, value }: { key: string; value: string }): Promise<Tags> {
    return OS.setTag(key, value).then((tagsSent) => {
      this.commit('setTag', tagsSent);

      return tagsSent;
    });
  }

  setTags(tags: Tags): Promise<Tags> {
    return OS.setTags(tags).then((tagsSent) => {
      this.commit('setTags', tagsSent);

      return tagsSent;
    });
  }

  async getTags(): Promise<Tags> {
    return new Promise((resolve) => {
      OS.getTags().then((tags) => {
        setTimeout(() => {
          this.commit('setTags', tags);

          return resolve(tags);
        }, 50);
      });
    });
  }

  async deleteAllTags(): Promise<string[]> {
    return new Promise((resolve) => {
      OS.deleteAllTags().then((tags) => {
        setTimeout(() => {
          this.commit('deleteAllTags');
          resolve(tags);
        }, 100);
      });
    });
  }

  notificationOpened(jsonData: OSNotificationOpenedResult) {
    console.log('notificationOpened', jsonData);
  }

  notificationReceived(notification: OSNotification) {
    console.log('notificationReceived', notification);
  }
}
