import { IUser, SentrySettings } from '@/types';

import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

import { name, version } from '../package.json';
import config from 'src/app.config.js';
import bus from '@/bus';
import { LOCALSTORAGE_APPDATA } from '@/misc';

function setTags() {
  const userSettings: Nullable<IUser> = window.storage?.[LOCALSTORAGE_APPDATA]?.user;

  Sentry.setTag('user.device', userSettings?.idDevice ?? 0);
  Sentry.setTag('user.id', userSettings?.idUser ?? 0);
  Sentry.setTag('user.name', userSettings?.display_name ?? '');
  Sentry.setTag('user.phone', userSettings?.username ?? '');

  if (window.device?.uuid) {
    Sentry.setTag('device.uuid', window.device.uuid);
  }
}

export const initSentry = () => {
  const sentrySettings: Nullable<SentrySettings> =
    window.storage[LOCALSTORAGE_APPDATA]?.settings?.modules?.sentry;

  if (!sentrySettings || sentrySettings.enabled) {
    Sentry.init({
      Vue,
      dsn: sentrySettings?.dsn || config.plugins.sentry,
      // @ts-ignore
      autoSessionTracking: true,
      // @ts-ignore
      integrations: [new Integrations.BrowserTracing()],
      release: version,
      environment: process.env.NODE_ENV,
      tracesSampleRate: sentrySettings?.rate,
      tracingOptions: {
        trackComponents: true,
      },
    } as any);

    Sentry.setTag('bundle.id', name);

    setTags();

    if (typeof sentrySettings?.tags === 'object') {
      for (const [key, value] of Object.entries(sentrySettings.tags)) {
        Sentry.setTag(key, value);
      }
    }

    bus.$on('sentrySetTag', (key: string, value: string) => {
      Sentry.setTag(key, value);
    });

    bus.$on('authComplete', setTags);
    bus.$on('authAfterLoginSuccess', setTags);
    bus.$on('authAfterRegisterSuccess', setTags);
  }
};
