import VueI18n from 'vue-i18n';

import { IBranch } from '@/types';
import { timeZone } from '@/utils/timeZone';
import config from '@/app.config.js';

function _getWorkingTimeFormatted(
  schedule: WorkingSchedule,
  branch: IBranch,
  timeZoneOptions: ITimeZoneOptions,
  i18n: VueI18n,
): string {
  const time = schedule.opened
    ? schedule.todayWorkingTime?.endTime || branch.endTime
    : schedule.willBeOpened?.startTime || branch.startTime;
  const parts = time.split(':');

  if (parts && parts.length >= 2) {
    const resultTime = timeZone(
      `${new Date().getFullYear()}-06-10T${parts[0].padStart(2, '0')}:${parts[1].padStart(
        2,
        '0',
      )}:00`,
      { type: 'timeShort', timezone: timeZoneOptions },
    );

    let text: string;
    // TODO: Hardcoded exception, remove after Working Days implementation
    if (
      branch.startTime === branch.endTime &&
      (!branch.days || branch.days.length === 7)
    ) {
      return i18n.t('global.branch.workingTime.workAllDay').toString();
    } else if (schedule.opened) {
      text = i18n.t('global.branch.workingTime.workUntil').toString();
    } else {
      const now = new Date();
      const day = [6, 0, 1, 2, 3, 4, 5][now.getDay()] + 1;

      if (schedule.willBeOpened) {
        if (schedule.willBeOpened.day === day) {
          text = i18n.t('global.branch.workingTime.openTodayAt').toString();
        } else if (schedule.willBeOpened.day - day === 1) {
          text = i18n.t('global.branch.workingTime.openTomorrowAt').toString();
        } else {
          text =
            i18n.t('global.branch.workingTime.opens').toString() +
            ' ' +
            (schedule.willBeOpened.day === 2
              ? i18n.t('global.branch.workingTime.on').toString()
              : i18n.t('global.branch.workingTime.on2').toString()) +
            ' ' +
            config.datetime.dateFormatI18n.dayNamesFuture[
              7 + [1, 2, 3, 4, 5, 6, 0][schedule.willBeOpened.day - 1]
            ].toLowerCase() +
            ' ' +
            i18n.t('global.branch.workingTime.at').toString();
        }
      } else {
        text = i18n.t('global.branch.workingTime.willOpenAt').toString();
      }
    }

    return text + ' ' + resultTime;
  }

  // Fallback
  if (branch.startTime !== '' && branch.endTime !== '') {
    return branch.startTime + ' - ' + branch.endTime;
  } else {
    return '';
  }
}

export default _getWorkingTimeFormatted;
