






























import Vue, { PropType } from 'vue';

import { IActionItem } from '../types';

import { appStoreMapper } from 'src/store/appstore';
import { actionsStoreMapper } from '../store';
import { formatDate, getImageThumbnailPath } from '@/utils';

import ActionPopup from './action-popup.vue';

export default Vue.extend({
  components: {
    ActionPopup,
  },
  props: {
    item: Object as PropType<IActionItem>,
  },
  data: () => ({
    popupOpened: false,
    innerItem: null as Nullable<IActionItem>,
  }),
  created() {
    this.innerItem = this.item;
  },
  computed: {
    date(): string {
      const startDate: Date | null = this.item.dateStart
        ? new Date(this.item.dateStart)
        : null;
      const endDate: Date | null = this.item.dateEnd ? new Date(this.item.dateEnd) : null;
      const nowDate = new Date();

      if (
        (!startDate && !endDate) ||
        (startDate &&
          Number.isNaN(startDate.getDate()) &&
          endDate &&
          Number.isNaN(endDate.getDate()))
      ) {
        return '';
      }

      if (
        (startDate && Number.isNaN(startDate.getTime())) ||
        (endDate && Number.isNaN(endDate.getTime()))
      ) {
        return '';
      }

      let startText = '';
      let endText = '';

      if (startDate && this.item.dateStart) {
        const startDateThisYear = nowDate.getFullYear() === startDate.getFullYear();
        const endDateSameMonth =
          !!endDate &&
          !!startDate &&
          startDate.getFullYear() === endDate.getFullYear() &&
          startDate.getMonth() === endDate.getMonth();

        startText =
          this.$t('modules.actions.startPrefix') +
          ' ' +
          formatDate(new Date(this.item.dateStart), {
            locale: this.locale,
            type: endDateSameMonth
              ? 'dateLongNoMonth'
              : startDateThisYear
              ? 'dateLongNoYear'
              : 'dateLong',
          });
      }

      if (endDate && this.item.dateEnd) {
        const endDateThisYear = nowDate.getFullYear() === endDate.getFullYear();
        const startDateSameYear =
          !!endDate && !!startDate && startDate.getFullYear() === endDate.getFullYear();

        endText =
          (startDate
            ? this.$t('modules.actions.endPrefix')
            : this.$t('modules.actions.endPrefixAlt')) +
          ' ' +
          formatDate(new Date(this.item.dateEnd), {
            locale: this.locale,
            type: startDate
              ? endDateThisYear && startDateSameYear
                ? 'dateLongNoYear'
                : 'dateLong'
              : endDateThisYear
              ? 'dateLongNoYear'
              : 'dateLong',
          });
      }

      return [startText, endText].join(' ').replace(/\s+/, ' ');
    },
    image(): string {
      return this.fullImagePath(
        getImageThumbnailPath(this.item.icon, 'scale', {
          width: this.deviceWidth,
          height: 0,
        }),
      );
    },
    ...actionsStoreMapper.mapGetters(['settings']),
    ...appStoreMapper.mapGetters(['clientName', 'fullImagePath', 'deviceWidth']),
    ...appStoreMapper.mapState(['locale']),
  },
  methods: {
    showPopup() {
      this.popupOpened = true;
    },
  },
});
