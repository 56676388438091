import { createI18nMessage, helpers } from '@vuelidate/validators';

import i18n from '@/translate/lang';

const validator = {
  $validator: helpers.regex(/^[a-zA-Zа-яА-ЯёЁ\- ]*$/),
};

function translationFunction(validator: string) {
  return i18n.t('global.validators.alpha.error').toString();
}

const withI18nMessage = createI18nMessage({ t: translationFunction });

export default withI18nMessage(validator);
