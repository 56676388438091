






















































import Vue, { PropType } from 'vue';
import { f7 } from 'framework7-vue';

import { IUser } from '@/types';
import { IActionItem } from '../types';

import bus from '@/bus';
import { appStoreMapper } from '@/store/appstore';
import { activateAction, getAction } from '../api';
import { formatNumber, getImageThumbnailPath } from '@/utils';
import { showErrorDialog, sendStat } from '@/misc';
import i18n from '@/translate/lang';

import ContentBlock from '@/components/content.vue';
import ThePopup from '@/components/ThePopup.vue';
import TheBarWrapper from '@/components/TheBarWrapper.vue';
import CatalogItem from '@/components/CatalogItem.vue';
import ExpansionBlock from '@/components/ExpansionBlock.vue';

export default Vue.extend({
  name: 'ActionPopup',
  inheritAttrs: false,
  components: { ContentBlock, ThePopup, TheBarWrapper, CatalogItem, ExpansionBlock },
  props: {
    item: Object as PropType<Nullable<IActionItem>>,
    id: {
      type: [Number, String] as PropType<number>,
      required: true,
    },
  },
  data: () => ({
    action: null as Nullable<IActionItem>,
    loading: false,
    errorText: '',
    productsVisible: false,
    products: [] as any[],
    getProducts: async (ids: number[]) => [] as any,
  }),
  async mounted() {
    let action: Nullable<IActionItem>;

    if (this.item) {
      action = this.item;
    } else {
      action = await this.getAction();
    }

    if (action) {
      if (action.productIds.length) {
        this.loading = true;

        try {
          this.products = await this.getProducts(action.productIds);
        } catch (e) {
          this.errorText = (e as unknown as any).error;
        } finally {
          this.loading = false;
        }
      }

      this.action = action;

      sendStat({ action: 'show-action', id: this.id }, this.$store as any);
    }
  },
  computed: {
    catalogItemsListComponentExists(): boolean {
      return !!this.$options.components?.['catalog-items-list'];
    },
    images(): string[] {
      return this.action?.icon
        ? [
            this.fullImagePath(
              getImageThumbnailPath(this.action.icon, 'scale', {
                size: this.deviceWidth,
              }),
            ),
          ]
        : [];
    },
    formattedBonusAmount(): string {
      if (!this.action?.bonus?.amount) {
        return '';
      }

      return formatNumber(
        this.action.bonus.amount,
        this.decimalOptions.decimalRoundingRule,
        this.decimalOptions.decimal,
      );
    },
    buttonLabel(): string {
      if (this.user?.idUser && !this.action?.bonus?.can_activate) {
        return i18n.t('modules.actions.body.activated').toString();
      }

      return `${i18n.t('modules.actions.body.acquire').toString()} <b>${
        this.formattedBonusAmount
      }</b> ${i18n.tc('global.bonus.plural', this.action?.bonus?.amount).toString()}`;
    },
    previewInContent(): number {
      const match = this.action?.content.match(/^<p><img.+?<\/p>/);

      if (!match) {
        return 0;
      }

      return match[0].length;
    },
    content(): string {
      if (!this.action) {
        return '';
      }

      // TODO: Delete in v53
      return this.previewInContent
        ? this.action.content.substring(this.previewInContent)
        : this.action.content;
    },
    activationAvailable(): boolean {
      return !!this.action?.bonus?.activate_on_page;
    },
    activationButtonDisabled(): boolean {
      return !!this.user?.idUser && !this.action?.bonus?.can_activate;
    },
    ...appStoreMapper.mapGetters([
      'decimalOptions',
      'fullImagePath',
      'deviceWidth',
      'user',
    ]),
  },
  methods: {
    async getAction(): Promise<Nullable<IActionItem>> {
      this.errorText = '';

      if (!this.action) {
        this.loading = true;

        try {
          const action = await getAction(this.id);

          if (!action) {
            this.errorText = i18n.t('modules.actions.body.notFound').toString();
          }

          return action;
        } catch (e) {
          this.errorText =
            (e as unknown as any).error ||
            i18n.t('modules.actions.body.notFound').toString();
        } finally {
          this.loading = false;
        }
      }
    },
    onReload() {
      this.getAction();
    },
    onActivateClick(userId?: number) {
      const id = userId || this.user?.idUser;

      if (!id) {
        bus.$emit('needRegister:popup', {
          callback: this.activateAfterLogin,
        });

        return false;
      }

      f7.preloader.show();

      activateAction(this.id)
        .then((response) => {
          const action = JSON.parse(JSON.stringify(this.action)) as IActionItem;
          action.bonus!.can_activate = !response.activated;

          this.$emit('update:item', action);
          bus.$emit('bonus:spent:add', -1 * action.bonus!.amount);

          f7.dialog.alert(
            i18n
              .t('modules.actions.body.actions.activation.message', [
                `${action.bonus!.amount} ${i18n
                  .tc('global.bonus.plural', action.bonus!.amount)
                  .toString()}`,
              ])
              .toString(),
            i18n.t('modules.actions.body.actions.activation.title').toString(),
            () => {
              f7.popup.close('.popup-universal');
            },
          );
        })
        .catch((error) => {
          showErrorDialog.call(this, { error });
        })
        .finally(() => {
          f7.preloader.hide();
        });
    },
    activateAfterLogin(user: IUser) {
      this.$nextTick(() => {
        this.onActivateClick(user.idUser);
      });
    },
    goToSearch() {
      f7.popup.close();
      f7.views.current.router.navigate(
        { path: '/catalog/search' },
        {
          props: {
            searchQuery: this.action?.title,
            products: this.products,
          },
        },
      );
    },
  },
  watch: {
    productsVisible() {
      if (this.productsVisible) {
        this.$nextTick(() => {
          const el = this.$el.querySelector('.page');
          f7.lazy.create(el as HTMLElement);
        });
      }
    },
  },
});
