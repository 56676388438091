
import { defineComponent, h, useCssModule } from 'vue';

import { slotSeeker } from '@/utils';

export default defineComponent({
  name: 'SettingsSlot',
  render() {
    const $style = useCssModule();

    return h('div', { class: $style.wrapper }, [
      this.$slots.default,
      slotSeeker('settings-slot'),
    ]);
  },
});
