import { Actions } from 'vuex-smart-module';

import SpecialOfferGetters from '@/modules/specialoffer/dist/store/getters';
import SpecialOfferMutations from '@/modules/specialoffer/dist/store/mutations';
import SpecialOfferState from '@/modules/specialoffer/dist/store/state';

export default class SpecialOfferActions extends Actions<
  SpecialOfferState,
  SpecialOfferGetters,
  SpecialOfferMutations,
  SpecialOfferActions
> {}
