















































import { defineComponent, PropType } from 'vue';
import StarRating from 'vue-star-rating';
import { f7 } from 'framework7-vue';

import { ISendFeedbackRequest } from '../types';

import { getModule, showErrorDialog } from 'src/misc';
import { appStoreMapper } from 'src/store/appstore';
import { feedbackStoreMapper } from '../store';
import useValidatationState from 'src/validators/validation-state';

import ListInputTextarea from 'src/components/fields/list-input-textarea.vue';

export default defineComponent({
  name: 'FeedbackForm',
  components: { ListInputTextarea, StarRating },
  props: {
    sheet: Boolean as PropType<any>,
    entityId: Number as PropType<number>,
  },
  data: () => ({
    buttonClicked: false,
    rating: 0,
    content: '',
    loading: false,
  }),
  setup() {
    const { validationState, onStateChanged, touchAll } = useValidatationState();
    return { validationState, onStateChanged, touchAll };
  },
  computed: {
    buttonDisabled(): boolean {
      return !this.rating && !this.content;
    },
    ...appStoreMapper.mapGetters(['defaultTab']),
    ...feedbackStoreMapper.mapGetters(['settings']),
  },
  methods: {
    doSendFeedback() {
      let params: ISendFeedbackRequest = {};

      if (this.content) {
        params.content = this.content;
      }

      if (this.rating) {
        params.quality = this.rating * 2;
      }

      if (this.entityId) {
        params.bonus = this.entityId;
      }

      f7.preloader.show();
      this.loading = true;

      this.sendFeedback(params)
        .then(() => {
          if (!this.settings.shareAfterFeedback) {
            f7.dialog.alert(
              this.$t('modules.feedback.form.result.text') as string,
              this.$t('modules.feedback.form.result.title') as string,
              () => {
                this.resetState();
                this.goToMainTab();
              },
            );
          }
        })
        .catch((error) => showErrorDialog.call(this, { error }))
        .finally(() => {
          f7.preloader.hide();
          this.loading = false;

          if (this.sheet) {
            if (
              this.settings.shareAfterFeedback &&
              this.settings.shareAfterFeedbackMinStars &&
              this.rating >= this.settings.shareAfterFeedbackMinStars
            ) {
              this.$emit('feedback:share');
            } else {
              this.onCancelClick();
            }
          }
        });
    },
    goToMainTab() {
      const defaultMainModule = getModule(this.defaultTab);

      f7.tab.show(`#${defaultMainModule.tabName}`);
    },
    onCancelClick() {
      f7.sheet.close();
    },
    onSendClick() {
      this.buttonClicked = true;

      this.$nextTick(() => {
        if (this.validationState.invalid) {
          this.touchAll();

          const invalidInput = this.$el.querySelector(
            '.item-input-invalid input, .item-input-invalid textarea',
          ) as HTMLInputElement;

          if (invalidInput) {
            invalidInput.focus();
          }

          return;
        }

        this.doSendFeedback();
      });
    },
    resetState() {
      this.content = '';
      this.rating = 0;
      this.buttonClicked = false;
    },
    ...feedbackStoreMapper.mapActions(['sendFeedback']),
  },
});
