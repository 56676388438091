import { createMapper, Module } from 'vuex-smart-module';

import StampCardActions from './actions';
import StampCardGetters from './getters';
import StampCardMutations from './mutations';
import StampCardState from './state';

const module = new Module({
  actions: StampCardActions,
  getters: StampCardGetters,
  mutations: StampCardMutations,
  namespaced: true,
  state: StampCardState,
});

export default module;

export const stampCardStoreMapper = createMapper(module);
