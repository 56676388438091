












































































import { defineComponent } from 'vue';
import { JSONView } from 'vue-json-component';

import deployJSON from 'src/../deploy.json';
import config from 'src/app.config.js';
import bus from 'src/bus';
import { appStoreMapper } from '@/store/appstore';
import Storage from '@/storage';
import { LOCALSTORAGE_RECORDSESSION } from '@/misc/const';

export default defineComponent({
  name: 'Debug',
  components: { JSONView },
  data: () => ({
    config,
    openAttempts: 0,
    recordSessionState: 0,
    popupVisible: false,
  }),
  mounted() {
    bus.$on('showDebugPopup', this.showDebugPopup);

    this.recordSessionState = Storage.getSync(LOCALSTORAGE_RECORDSESSION);
  },
  destroyed() {
    bus.$off('showDebugPopup', this.showDebugPopup);
  },
  computed: {
    userAgent(): string {
      return window.navigator.userAgent;
    },

    platform(): 'android' | 'ios' {
      return this.$theme.ios ? 'ios' : 'android';
    },

    appDataJSON(): any {
      return this.appData ? JSON.parse(JSON.stringify(this.appData)) : {};
    },

    appName(): string {
      return typeof deployJSON.name === 'string'
        ? deployJSON.name
        : deployJSON.name[this.platform];
    },

    appVersion(): string {
      return typeof deployJSON.version === 'string'
        ? deployJSON.version
        : deployJSON.version[this.platform];
    },
    ...appStoreMapper.mapState(['appData']),
  },
  methods: {
    showDebugPopup() {
      if (this.openAttempts < 10) {
        this.openAttempts++;
      } else {
        this.$f7.popup.open('.popup-debug');
      }
    },

    toggleSessionRecord() {
      this.recordSessionState = this.recordSessionState != 1 ? 1 : 0;

      Storage.setSync(LOCALSTORAGE_RECORDSESSION, this.recordSessionState);

      bus.$emit('recordSession', this.recordSessionState);

      if (!this.recordSessionState) {
        this.$f7?.dialog.alert(
          'Записи сессий будут прекращены после перезагрузки приложения. Чтобы снова записывать отладночные сессии, необходимо повторно включить соответствующую опцию',
          'Выключение записи сессий',
        );
      }
    },

    onPopupClosed() {
      this.popupVisible = false;
    },

    onPopupOpen() {
      this.popupVisible = true;
    },
  },
});
