






















import { defineComponent } from "vue";

import { ReceiveType } from '../types';

import i18n from '@/translate/lang';
import { appStoreMapper } from 'src/store/appstore';
import { catalogStoreMapper } from '../store';

export default defineComponent({
  name: 'WorkingTime',
  props: {
    checkDayAndTime: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    ReceiveType,
  }),
  computed: {
    visible(): boolean {
      return this.checkDayAndTime ? this.notWorkingTime() : true;
    },
    closedText(): string {
      let text = '';

      if (this.receive === 'pickup') {
         text = this.settings.pickup?.closedText ?? '';
      } else if (this.receive === 'delivery') {
         text = this.settings.delivery?.closedText ?? '';
      }

      return text || i18n.t('modules.catalog.workingTime.closed').toString();
    },
    closed(): boolean {
      let flag = false;

      if (this.receive === ReceiveType.pickup && this.notWorkingTime(this.pickupPoint)) {
        flag = true;
      } else if (this.receive === ReceiveType.delivery && this.deliveryNotWorkingTime) {
        flag = true;
      }

      return flag;
    },
    ...appStoreMapper.mapGetters(['pickupPoints']),
    ...catalogStoreMapper.mapGetters([
      'deliveryDays',
      'deliveryDaysLabel',
      'deliveryStartLabel',
      'deliveryEndLabel',
      'notWorkingTime',
      'settings',
      'workingTimeLabel',
      'deliveryNotWorkingTime',
    ]),
    ...catalogStoreMapper.mapState(['pickupPoint', 'receive']),
  },
});
