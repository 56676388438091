









































































import Vue from 'vue';

import { IHistoryItem, OperationsDetail, OperationsDetails } from '../types';

import bus from 'src/bus';
import { appStoreMapper } from 'src/store/appstore';
import { historyStoreMapper } from '../store';
import { errorHandler } from 'src/misc';
import * as API from '../api';
import { timeZone, formatNumber } from '@/utils';

import BonusIcon from '@/components/bonus-icon.vue';
import HistoryList from './HistoryList.vue';

export default Vue.extend({
  components: {
    HistoryList,
    BonusIcon,
  },
  data: () => ({
    allLoaded: false,
    limit: 30,
    loadHistoryPromise: null as any,
    operationsDetail: null as Nullable<OperationsDetails>,
    loadingOperationsDetail: false,
  }),
  mounted() {
    this.loadHistory({ reset: true });
    this.loadOperationsDetail();

    bus.$on('authAfterLogout', () => {
      setTimeout(() => {
        this.allLoaded = false;

        this.resetAll();
      }, 500);
    });
  },
  computed: {
    historyDidLoad(): boolean {
      return (
        this.allLoaded || (!!this.history.length && this.history.length < this.limit)
      );
    },
    preloaderClasses(): any[] {
      return [
        (this as any).$style.preloader,
        { bottom: !this.historyDidLoad && this.history.length },
      ];
    },
    showPreloader(): boolean {
      return !this.historyDidLoad && (this.loadingHistory || this.loadingHistoryAfter);
    },
    bonusActual(): number {
      return this.operationsDetail?.bonusValue || this.user?.bonus || 0;
    },
    bonusFormatted(): string {
      return formatNumber(
        this.bonusActual,
        this.decimalOptions.decimalRoundingRule,
        this.decimalOptions.decimal,
      );
    },
    awaitForActivation(): OperationsDetail[] {
      return this.operationsDetail?.activatedAt || [];
    },
    awaitToBurn(): OperationsDetail[] {
      return this.operationsDetail?.burnAt || [];
    },
    bonusWithoutBurnDate(): number {
      const burnBonuses = this.awaitToBurn.reduce((acc, cur) => {
        acc += cur.value;
        return acc;
      }, 0);
      const totalBonuses = this.bonusActual;

      return totalBonuses - burnBonuses;
    },
    ...appStoreMapper.mapGetters([
      'defaultTab',
      'user',
      'timeZoneOptions',
      'decimalOptions',
    ]),
    ...appStoreMapper.mapState(['currentTab', 'online', 'tabDidOpen', 'locale']),
    ...historyStoreMapper.mapGetters(['settings']),
    ...historyStoreMapper.mapState([
      'history',
      'loadingHistory',
      'loadingHistoryAfter',
      'triggerHistoryUpdate',
    ]),
  },
  methods: {
    loadHistory({
      from,
      limit,
      reset,
    }: { from?: boolean; limit?: number; reset?: boolean } = {}) {
      if (!limit) limit = this.limit;
      if (this.loadHistoryPromise) {
        return this.loadHistoryPromise;
      }

      this.loadHistoryPromise = this.getHistory({ from, limit, reset })
        .then((operations) => {
          this.allLoaded = !(operations as IHistoryItem[]).length;
        })
        .catch((error) => {
          errorHandler.call(this, error);
        })
        .finally(() => {
          this.loadHistoryPromise = null;
        });

      return this.loadHistoryPromise;
    },
    onInfinite(): void {
      if (!this.loadingHistory && !this.loadingHistoryAfter && !this.historyDidLoad) {
        this.getHistory({ from: true, limit: this.limit })
          .then((operations) => {
            this.allLoaded = (operations as IHistoryItem[]).length < this.limit;
          })
          .catch((error) => {
            errorHandler.call(this, error);
          });
      }
    },
    onPTRRefresh(done: () => void): void {
      const promises: Promise<void>[] = [];

      if (!this.loadingHistory && !this.loadingHistoryAfter) {
        promises.push(
          this.getHistory({ limit: this.limit }).then((operations) => {
            this.allLoaded = !(operations as IHistoryItem[]).length;
          }),
        );
      }

      if (!this.loadingOperationsDetail) {
        promises.push(this.loadOperationsDetail());
      }

      Promise.allSettled(promises)
        .catch((error) => {
          errorHandler.call(this, error);
        })
        .finally(done);
    },
    async loadOperationsDetail() {
      this.loadingOperationsDetail = true;

      try {
        this.operationsDetail = await API.getOperationsDetail();
      } finally {
        this.loadingOperationsDetail = false;
      }
    },
    getFormattedDate(date: DateISODate): string {
      if (!isNaN(new Date(date).getTime())) {
        return timeZone(date, {
          locale: this.locale,
          timezone: this.timeZoneOptions,
        }).toLowerCase();
      } else {
        return '—';
      }
    },
    formatNumber(num: number): string {
      return formatNumber(
        num,
        this.decimalOptions.decimalRoundingRule,
        this.decimalOptions.decimal,
      );
    },
    ...historyStoreMapper.mapMutations(['setTriggerHistoryUpdate']),
    ...historyStoreMapper.mapActions(['resetAll', 'getHistory']),
  },
  watch: {
    online(value: boolean) {
      if (value && !this.history.length) {
        this.loadHistory({ reset: true });
      }
    },
    triggerHistoryUpdate(value: boolean) {
      if (value) {
        this.allLoaded = false;

        this.loadHistory({ reset: true });

        this.setTriggerHistoryUpdate(false);
      }
    },
  },
});
