import { Store } from 'vuex';
import { Context, Getters } from 'vuex-smart-module';

import StampCardState from './state';
import { IModuleSettings } from '../types';

import AppStore from '@/store/appstore';

export default class StampCardGetters extends Getters<StampCardState> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  get settings(): Nullable<IModuleSettings> {
    return this.app.state.appData.settings.modules?.stampcard;
  }
}
