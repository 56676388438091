
import { defineComponent } from 'vue';
import { CreateElement } from 'vue';
import { VNodeData } from 'vue/types/vnode';
import { f7 } from 'framework7-vue';

import i18n from '@/translate/lang';
import config from '../auth.conf.json';
import { appStoreMapper } from 'src/store/appstore';
import { authStoreMapper } from '../store';

export default defineComponent({
  name: 'AuthTabbarItem',
  render(this: any, h: CreateElement) {
    const data: VNodeData = {
      class: {
        ['tab-link-' + config.tabName]: true,
      },
      props: {
        key: config.name,
        href: this.isAuthorized && this.settings.tab ? `#${config.tabName}` : undefined,
        icon: config.tabbarIcon,
        text: this.settings.tabbarTitle || i18n.t(`modules.${config.name}.tabbarTitle`),
        'tab-link': true,
        'tab-link-active': this.defaultTab
          ? config.name === this.defaultTab
          : config.name === 'home',
      },
    };

    if (!this.isAuthorized) {
      data.on = {
        click: () => {
          f7.views.current.router.navigate('/auth', {
            props: { openProfile: true },
          });
        },
      };
    } else if (!this.settings.tab) {
      data.on = {
        click: () => {
          f7.views.current.router.navigate('/profile');
        },
      };
    }

    return h('f7-link', data);
  },
  computed: {
    ...appStoreMapper.mapGetters(['defaultTab']),
    ...authStoreMapper.mapGetters(['isAuthorized', 'settings']),
  },
});
