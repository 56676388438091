





















































import Vue from 'vue';

import { IBranch } from '@/types';

import bus from '@/bus';
import { appStoreMapper } from '@/store/appstore';
import { mapsStoreMapper } from '@/modules/maps/dist/store';

import MapBlock from '@/components/MapBlock.vue';
import MapsSheetBranches from './MapsSheetBranches.vue';
import MapsSheetBranchInfo from './MapsSheetBranchInfo.vue';

export default Vue.extend({
  name: 'maps2',
  components: { MapBlock, MapsSheetBranches, MapsSheetBranchInfo },
  data: () => ({
    map: null as Nullable<any>,
    mapCenter: {
      lat: 0,
      lng: 0,
    } as IMapPosition,
    markersList: [] as Array<[any, IMapPoint]>,
    branch: null as Nullable<IBranch>,
    branchSelected: null as Nullable<IBranch>,
    stepOpened: false,
    zoomToCurrentPosition: false,
    waitingForCoordinates: false,
    branchOpened: false,
  }),
  created() {
    this.mapCenter = {
      lat: parseFloat(this.settings.center?.lat || '0'),
      lng: parseFloat(this.settings.center?.lng || '0'),
    };
  },
  mounted() {
    bus.$emit('maps:branches:sheet-open');
    bus.$on('backButtonOverride', this.goBack);
  },
  beforeDestroy() {
    bus.$emit('maps:branches:sheet-close');
    bus.$off('backButtonOverride', this.goBack);
  },
  computed: {
    pointsNumber(): any {
      const $style = (this as any).$style;

      return {
        [$style.more]: this.mapPoints.length > 3,
        [$style.three]: this.mapPoints.length === 3,
        [$style.two]: this.mapPoints.length === 2,
        [$style.one]: this.mapPoints.length === 1,
        [$style.zero]: this.mapPoints.length === 0,
      };
    },
    mapPoints(): IMapPoint[] {
      return this.branches.map((branch) => ({
        id: branch.idBranch as unknown as number,
        position: { lat: parseFloat(branch.lat), lng: parseFloat(branch.lng) },
      }));
    },
    selectedMapPoint(): Nullable<IMapPoint> {
      if (this.branchSelected) {
        return {
          id: this.branchSelected.idBranch,
          position: {
            lat: parseFloat(this.branchSelected.lat),
            lng: parseFloat(this.branchSelected.lng),
          },
        };
      } else if (this.zoomToCurrentPosition) {
        return {
          id: 0,
          position: {
            lat: this.currentCoords.lat,
            lng: this.currentCoords.lng,
          },
        };
      }

      return null;
    },
    ...appStoreMapper.mapState(['currentCoords']),
    ...appStoreMapper.mapGetters(['branches']),
    ...mapsStoreMapper.mapGetters(['settings']),
  },
  methods: {
    goBack() {
      bus.$emit('maps:branches:sheet-close');
      this.$f7.views.current.router.back();
    },
    getCoords({ silent }: { silent?: boolean } = {}) {
      this.waitingForCoordinates = true;

      this.getCurrentCoordinates({ silent })
        .then((coords) => {
          const _coords = coords as Nullable<Coords>;

          if (_coords && !silent) {
            if (_coords.lat || _coords.lng) {
              this.zoomToCurrentPosition = true;

              this.mapCenter = {
                lat: parseFloat(_coords.lat as unknown as string),
                lng: parseFloat(_coords.lng as unknown as string),
              };
            } else {
              this.$f7.dialog.alert(
                this.$t('global.location.error.text').toString(),
                this.$t('global.location.error.title').toString(),
              );
            }
          }
        })
        .finally(() => {
          this.waitingForCoordinates = false;
        });
    },
    onMapInit(map: any) {
      this.map = map;
      this.getCoords({ silent: true });
    },
    onMarkerList(list: any[]) {
      this.markersList = list;
    },
    onMarkerClick(point: IMapPoint) {
      const branch = this.branches.find(
        (b) => (b.idBranch as unknown as number) === point.id,
      ) as IBranch;

      this.onBranchClick(branch);
    },
    onBranchClick(branch: IBranch) {
      this.zoomToCurrentPosition = false;

      this.mapCenter = {
        lat: parseFloat(branch.lat),
        lng: parseFloat(branch.lng),
      };

      this.branchSelected = branch;
      this.branchOpened = true;

      bus.$emit('maps:branches:sheet-close');

      this.$nextTick(() => {
        let panByY;

        switch (this.branches.length) {
          case 1:
            panByY = 70;
            break;

          case 2:
            panByY = 50;
            break;

          case 3:
            panByY = 20;
            break;

          default:
            panByY = 0;
        }

        this.map.panBy(0, panByY);
      });
    },
    pageBeforeOut() {
      this.branchOpened = false;
    },
    ...appStoreMapper.mapActions(['getCurrentCoordinates']),
  },
  watch: {
    branchOpened() {
      if (!this.branchOpened) {
        bus.$emit('maps:branches:sheet-open');
        if (this.stepOpened) {
          bus.$emit('maps:branches:step-open');
        }
      }
    },
  },
});
