export interface IModuleSettings {
  buttons?: IButton[];
  title?: string;
  tabbarTitle?: string;
}

export interface IButton {
  type: ButtonType;
  title: string;
  link?: string;
}

export enum ButtonType {
  feedback = 'feedback',
  phone = 'phone',
  site = 'site',
  telegram = 'telegram',
  whatsapp = 'whatsapp',
  viber = 'viber',
}
