import { IModule } from 'src/types';

import Vue from 'vue';
import { registerModule } from 'vuex-smart-module';
import {
  faCommentAlt,
  faHomeAlt as farHomeAlt,
} from '@fortawesome/pro-regular-svg-icons';
import { faGlobe, faHomeAlt } from '@fortawesome/pro-light-svg-icons';
import { faWhatsapp, faViber } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import * as Misc from 'src/misc';
import config from './home.conf.json';

import store from 'src/store';
import HomeStore from './store';

import Home from './components/home.vue';
import HomeNavbarSlotRight from './components/home-navbar-slot-right.vue';
import HomeSlotButtons from './components/home-slot-buttons.vue';
import HomeSlotTop from './components/home-slot-top.vue';
import HomeMenuItem from './components/home-menu-item.vue';
import HomeTabbarItem from './components/home-tabbar-item.vue';
import HomeContacts from './components/home-contacts.vue';

library.add(faCommentAlt, faGlobe, faWhatsapp, faViber, faHomeAlt, farHomeAlt);

export function init() {
  Vue.component('home', Home);
  Vue.component('home-navbar-slot-right', HomeNavbarSlotRight);
  Vue.component('home-slot-top', HomeSlotTop);
  Vue.component('home-slot-buttons', HomeSlotButtons);
  Vue.component('home-menu-item', HomeMenuItem);
  Vue.component('home-tabbar-item', HomeTabbarItem);

  const routes = [
    {
      component: Vue.component('home'),
      path: config.url,
    },
    {
      component: HomeContacts,
      path: '/home/contacts',
    },
  ];

  registerModule(store, ['home'], 'home/', HomeStore);

  Misc.registerModuleConfig(config as IModule);
  Misc.registerModuleRoutes(routes);
}
