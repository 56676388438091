









import { OSNotificationOpenedResult } from '@/modules/notifications/dist/types/onesignal';
import { idAction } from '../types';

import Vue from 'vue';

import config from '../actions.conf.json';
import bus from 'src/bus';

import ActionPopup from './action-popup.vue';

export default Vue.extend({
  components: {
    ActionPopup,
  },
  data: () => ({
    id: null as Nullable<number>,
    popupOpened: false,
  }),
  destroyed() {
    bus.$off('action-push', this.handlePush);
    bus.$off('actions-list', this.openActionsList);
  },
  mounted() {
    bus.$on('action-push', this.handlePush);
    bus.$on('actions-list', this.openActionsList);
  },
  methods: {
    async handlePush(data: OSNotificationOpenedResult) {
      this.id = data.notification.additionalData
        ?.idConnectedEntity as unknown as idAction;
      this.popupOpened = !!this.id;
    },
    openActionsList() {
      this.$f7.tab.show(`#${config.tabName}`);
    },
  },
});
