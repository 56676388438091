import { INewsItem } from '../types';

import * as Vuex from 'vuex';
import {
  Actions,
  Context,
  Getters,
  Module,
  Mutations,
  createMapper,
} from 'vuex-smart-module';

import AppStore from 'src/store/appstore';
import { IModuleSettings } from '../types';
import { Store } from 'vuex';
import * as API from '../api';

let getNewsPromise: Nullable<Promise<INewsItem[]>> = null;

export class NewsState {
  newsList: INewsItem[] = [];
  allNewsLoaded = false;
}

class NewsGetters extends Getters<NewsState> {
  app!: Context<typeof AppStore>;

  $init(store: Store<typeof AppStore>): void {
    this.app = AppStore.context(store);
  }

  get settings(): IModuleSettings {
    const settings = this.app.state.appData.settings?.modules?.news || {};

    settings.cardShowDate = settings.cardShowDate ?? true;
    settings.cardShowTitle = settings.cardShowTitle ?? true;
    settings.cardCutTitle = settings.cardCutTitle ?? false;
    settings.cardTitleOverImage = settings.cardTitleOverImage ?? false;
    settings.limit = settings.limit ?? 10;

    return settings;
  }
}

class NewsMutations extends Mutations<NewsState> {
  setNews(news: INewsItem[]) {
    this.state.newsList = news;
  }

  pushNews(news: INewsItem[]) {
    this.state.newsList = this.state.newsList.concat(news);
  }

  setAllNewsLoaded(value: boolean) {
    this.state.allNewsLoaded = value;
  }
}

class NewsActions extends Actions<NewsState, NewsGetters, NewsMutations, NewsActions> {
  appstore!: Context<typeof AppStore>;

  $init(store: Vuex.Store<any>): void {
    this.appstore = AppStore.context(store);
  }

  getNews({ after, limit }: { after?: boolean; limit: number }): Promise<INewsItem[]> {
    if (getNewsPromise) {
      return getNewsPromise;
    }

    const preloadedList = this.getters['settings'].list;
    if (preloadedList) {
      this.commit('setNews', preloadedList);
      this.commit('setAllNewsLoaded', true);

      return Promise.resolve(preloadedList);
    }

    const offset = after ? this.state.newsList.length : undefined;

    return (getNewsPromise = API.getNews({ limit, offset })
      .then((news) => {
        if (after) {
          this.commit('pushNews', news);
        } else {
          this.commit('setNews', news);
        }

        const allNewsLoaded = !news.length || news.length < limit;
        this.commit('setAllNewsLoaded', allNewsLoaded);

        return news;
      })
      .finally(() => {
        getNewsPromise = null;
      }));
  }
}

const module = new Module({
  actions: NewsActions,
  getters: NewsGetters,
  mutations: NewsMutations,
  namespaced: true,
  state: NewsState,
});

export default module;

export const newsStoreMapper = createMapper(module);
