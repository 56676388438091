
import { CreateElement, RenderContext } from 'vue';

const slotData: any = {};

function getSlots(h: CreateElement, ctx: RenderContext) {
  let components: any = [];

  window.modules.forEach((module) => {
    const slotWanted = module['registration-slot-form'];

    if (slotWanted) {
      slotWanted.forEach((slot: any) => {
        components.push(
          h(slot, {
            on: {
              input({ key, value }: { key: string; value: string }) {
                slotData[key] = value;

                // @ts-ignore
                ctx.listeners.onUpdate(slotData);
              },
            },
          }),
        );
      });
    }
  });

  return components;
}

export default {
  functional: true,
  render(h: CreateElement, ctx: RenderContext) {
    return getSlots.call(this, h, ctx);
  },
};
