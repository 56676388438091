import config from '@/app.config.js';

type PhotoId = string | number;
type MontageRule = 'crop' | 'scale';
type ImageExtension = 'jpg' | 'png';

interface IOptions {
  size?: number;
  width?: number;
  height?: number;
  extension?: ImageExtension;
  deviceWidth?: number;
  divideBy?: number;
}

function _getImageThumbnailPath(photoId: PhotoId): string;
function _getImageThumbnailPath(photoId: PhotoId, montage: MontageRule): string;
function _getImageThumbnailPath(photoId: PhotoId, options: IOptions): string;
function _getImageThumbnailPath(
  photoId: PhotoId,
  montage: MontageRule,
  options: IOptions,
): string;
function _getImageThumbnailPath(
  photoId: PhotoId,
  montageOrOptions: MontageRule | IOptions = 'crop',
  options?: IOptions,
) {
  let montage;

  if (typeof montageOrOptions === 'string') {
    montage = montageOrOptions;
  } else {
    options = montageOrOptions;
  }

  if (typeof montage === 'undefined') montage = 'crop';
  if (typeof options === 'undefined') options = {};
  if (!options.divideBy) {
    options.divideBy = 2;
  }
  if (options.size) {
    options.width = options.size;
    options.height = options.size;
  }
  if (options.width == null)
    options.width = options.deviceWidth
      ? Math.ceil(options.deviceWidth / options.divideBy)
      : Math.ceil((window.innerWidth * window.devicePixelRatio) / options.divideBy);
  if (options.height == null) {
    // noinspection JSSuspiciousNameCombination
    options.height = options.width;
  }
  if (!options.extension) options.extension = 'jpg';

  return `${config.api.images}${photoId}/${options.height}/${options.width}/${montage}.${options.extension}`;
}

export default _getImageThumbnailPath;
