




import { OSNotificationOpenedResult } from '@/modules/notifications/dist/types/onesignal';
import { idNews } from '../types';

import Vue from 'vue';

import config from '../news.conf.json';
import bus from 'src/bus';

import NewsPopup from './news-popup.vue';

export default Vue.extend({
  components: {
    NewsPopup,
  },
  data: () => ({
    id: null as Nullable<number>,
    popupOpened: false,
  }),
  destroyed() {
    bus.$off('news-push', this.handlePush);
    bus.$off('news-list', this.openActionsList);
  },
  mounted() {
    bus.$on('news-push', this.handlePush);
    bus.$on('news-list', this.openActionsList);
  },
  methods: {
    async handlePush(data: OSNotificationOpenedResult) {
      this.id = data.notification.additionalData?.idConnectedEntity as unknown as idNews;
      this.popupOpened = !!this.id;
    },
    openActionsList() {
      this.$f7.tab.show(`#${config.tabName}`);
    },
  },
});
