import { IBranch } from '@/types';
import { ISuggestion } from '../api';
import { ICheckAddressIsInDeliveryZoneReturn, IModuleSettings } from '../types';

export function checkAddressIsInDeliveryZone({
  googleMaps,
  map,
  mapShapes,
  suggestion,
  branches,
  settings,
}: {
  googleMaps: any;
  map: any;
  mapShapes: Map<any, any>;
  suggestion: ISuggestion;
  branches: IBranch[];
  settings: IModuleSettings;
}): ICheckAddressIsInDeliveryZoneReturn {
  const result: ICheckAddressIsInDeliveryZoneReturn = {
    inZone: false,
  };

  if (!map || !suggestion?.data?.geo_lat || !suggestion?.data?.geo_lon) {
    result.error = 'Map object or geocoder lan/lng does not exist';
    result.inZone = !settings.delivery?.checkZoneRequired;

    return result;
  }

  const point = new window.google.maps.LatLng(
    suggestion.data.geo_lat,
    suggestion.data.geo_lon,
  );

  try {
    let shortestDistance: number = 0;

    loop1: for (const branch of branches) {
      if (!branch.lat || !branch.lng || !branch.shape) continue;

      switch (branch.shape.type) {
        case 'circle':
          const distance = googleMaps.geometry.spherical.computeDistanceBetween(
            point,
            new googleMaps.LatLng(branch.lat, branch.lng),
          );

          if (distance <= branch.shape.radius) {
            result.inZone = true;

            if (!shortestDistance || distance < shortestDistance) {
              shortestDistance = distance;
              result.branch = branch;
            }
          }

          break;

        case 'polygon':
          if (!mapShapes.has(branch)) {
            const shape = new googleMaps.Polygon({
              map,
              paths: branch.shape.paths,
            });

            mapShapes.set(branch, shape);
          }

          const shape = mapShapes.get(branch);

          result.inZone = googleMaps.geometry.poly.containsLocation(point, shape);

          if (result.inZone) {
            result.branch = branch;
            break loop1;
          }

          break;

        default:
          break;
      }
    }
  } catch (e) {
    result.error = `Google maps API failed: ${e}`;
    result.inZone = !settings.delivery?.checkZoneRequired;
  }

  return result;
}
