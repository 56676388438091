





























import Vue from 'vue';
import { f7 } from 'framework7-vue';
import { Interval, Duration, intervalToDuration } from 'date-fns';

import { UserPush } from '../types';

import * as API from '../api';
import { appStoreMapper } from '@/store/appstore';
import { notificationsStoreMapper } from '../store';
import { showErrorDialog } from '@/misc';
import { timeZone } from '@/utils';

import NotificationsItem from './NotificationsItem.vue';

export default Vue.extend({
  name: 'Notifications',
  components: { NotificationsItem },
  data: () => ({
    pushes: [] as UserPush[],
  }),
  mounted() {
    this.loadUserPushes();
  },
  computed: {
    pushesByDates(): { [key: string]: UserPush[] } {
      const dates: { [key: string]: UserPush[] } = {};

      this.pushes.forEach((push) => {
        const pushDate = timeZone(new Date(push.date), { type: 'dateShort' });
        if (!dates[pushDate]) {
          dates[pushDate] = [push];
        } else {
          dates[pushDate].push(push);
        }
      });

      return dates;
    },
    ...appStoreMapper.mapGetters(['timeZoneOptions']),
    ...appStoreMapper.mapState(['locale']),
    ...notificationsStoreMapper.mapGetters(['settings']),
  },
  methods: {
    timeZone,
    async loadUserPushes() {
      f7.preloader.show();

      try {
        this.pushes = await API.getUserPushes();
      } catch (error) {
        showErrorDialog({ error });
      } finally {
        f7.preloader.hide();
      }
    },
    onPTRRefresh(done: () => void) {
      done();

      this.loadUserPushes();
    },
    getTitle(date: UserPush[]) {
      const now = new Date();
      const pushDate = new Date(date[0].date);
      const interval: Interval = { start: pushDate, end: now };
      const duration: Duration = intervalToDuration(interval);

      let result = '';

      if (duration.days != null) {
        if (duration.days <= 0) {
          return 'Сегодня';
        } else if (duration.days === 1) {
          result = 'Вчера, ';
        }
      }

      result += timeZone(date[0].date, {
        locale: this.locale,
        type:
          now.getFullYear() === pushDate.getFullYear() ? 'dateLongNoYear' : 'dateLong',
        timezone: this.timeZoneOptions,
      });

      return result;
    },
  },
});
