import { createI18nMessage } from '@vuelidate/validators';

import i18n from '@/translate/lang';

const validator = {
  $validator: (value: string) =>
    value === '' ||
    /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/.test(value),
};

function translationFunction(validator: string) {
  return i18n.t('global.validators.date.error').toString();
}

const withI18nMessage = createI18nMessage({ t: translationFunction });

export default withI18nMessage(validator);
